import { React, useState, useRef } from "react";
import SubmitModal from "../shared/SubmitModal";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import Notifications from "../../services/Notifications";
import { withStorefrontConfig } from "../../hooks/StorefrontSettingsContext";

const ClaimAttachment = (props) => {
  const fileInputRef = useRef("");
  const [files, setFiles] = useState(() => props.claimLine?.uploadFiles || []);
  const [filesPendingRemoval, setFilesPendingRemoval] = useState([]);

  const allowedExtensions =
    props.StorefrontConfig.settingsList.WarrantyAttachmentFileTypes.toString();
  const fileCountLimit = parseInt(
    props.StorefrontConfig.settingsList.FileCount
  );
  const fileSizeLimit = parseInt(props.StorefrontConfig.settingsList.FileSize);
  const maxFileNameLength = parseInt(
    props.StorefrontConfig.settingsList.MaxFileNameLength
  );

  const handleChange = (event) => {
    if (validateFiles(event.target.files)) {
      setFiles([...files, ...event.target.files]);
    }
  };

  const validateFiles = (filesData) => {
    let validFile = true;

    if (filesData.length >= fileCountLimit || files?.length >= fileCountLimit) {
      validFile = false;

      return Notifications.warning(
        localized.FileCountLimit + " " + fileCountLimit
      );
    }

    Array.from(filesData).map((file) => {
      if (
        allowedExtensions.includes(file.name.split(".").pop().toLowerCase()) ===
        true
      ) {
        if (file.size > fileSizeLimit) {
          validFile = false;

          return Notifications.error(localized.FileSizeLimit);
        }

        if (file.name.length > maxFileNameLength) {
          validFile = false;

          return Notifications.error(
            localized.MaxFileNameLengthMessage + maxFileNameLength
          );
        }
      } else {
        validFile = false;

        return Notifications.error(
          localized.SupportedAttachmentTypes + " " + allowedExtensions
        );
      }
    });

    return validFile;
  };

  const onContinue = (e) => {
    props.setShowModal(false);
    props.uploadFiles(files, props.claimLine);
    setFilesPendingRemoval([]);
  };

  const removeAttachment = (index) => {
    const deletedFiles = [...files];
    deletedFiles.splice(index, 1);
    setFilesPendingRemoval([...filesPendingRemoval, files[index]]);
    setFiles(deletedFiles);
  };

  const cancelRemove = () => {
    setFiles([...files, ...filesPendingRemoval]);
    setFilesPendingRemoval([]);
    props.setShowModal(false);
  };

  const { localized } = props;
  return (
    <div className="flex">
      <SubmitModal
        isOpen={props.collapsed}
        onSubmit={onContinue}
        onClose={cancelRemove}
        showFooter={true}
        submitText={localized.Continue}
        closeText={localized.Cancel}
      >
        <Container>
          <Row>
            <Col xs={8}>
              <div className="heading2" style={{ paddingBottom: "2rem" }}>
                {localized.AttachFiles}
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <div className="heading4">{localized.SelectedFiles}</div>
            </Col>
          </Row>

          <Row style={{ paddingTop: "1rem" }}>
            {files.map((file, key) => renderFiles(file, key))}
          </Row>

          <Row>
            <Col xl={3} style={{ textAlign: "right", paddingTop: "1rem" }}>
              <div className="claim-submission-button">
                <div className="fele-content">
                  <div className="button-wrapper large">
                    <button
                      className="button search-button button-alt text-nowrap px-5"
                      onClick={() => fileInputRef.current.click()}
                    >
                      <input
                        accept={props.accept}
                        onChange={handleChange}
                        multiple={true}
                        ref={fileInputRef}
                        type="file"
                        name="file"
                        hidden
                        className="form-control"
                      />
                      {localized.BrowseFiles}
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={12} style={{ paddingTop: "1rem" }}>
              <div
                className="heading5"
                style={{ paddingTop: "0.5rem", fontSize: "1.5rem" }}
              >
                {localized.MaxFileSizeLabel}
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <div
                className="heading5"
                style={{ paddingTop: "0.5rem", fontSize: "1.5rem" }}
              >
                {localized.SupportedAttachmentTypes + "  " + allowedExtensions}
              </div>
            </Col>
          </Row>
        </Container>
      </SubmitModal>
    </div>
  );

  function renderFiles(file, index) {
    return (
      file &&
      file.name !== undefined && (
        <div key={index} className="fele-content">
          <Col lg="12">
            {file.name} &nbsp;
            <div
              className="button-wrapper"
              style={{ height: "3rem", marginLeft: "2rem", minWidth: "1rem" }}
            >
              <button
                className="remove"
                value={index}
                onClick={() => removeAttachment(index)}
                alt="remove"
                style={{ color: "red" }}
              >
                x
              </button>
            </div>
          </Col>
        </div>
      )
    );
  }
};

ClaimAttachment.propTypes = {
  uploadFiles: PropTypes.func,
};

export default withStorefrontConfig(ClaimAttachment);
