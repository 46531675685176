import LocalizedStrings from 'react-localization';
import Localization from './../services/Localization';

class HelpStrings {
    localized = new LocalizedStrings({
        en: {
            EmailUs: "Email Us",
            RequiredInformation: "Required Information",
            SelectASubject: "Select a Subject",
            Subject: "Subject",
            Customer: "Customer",
            CustomerNumber: "Customer Number",
            FirstName: "First Name",
            LastName: "Last Name",
            Email: "Email",
            Phone: "Phone",
            Extension: "Extension (Optional)",
            Fax: "Fax (Optional)",
            Message: "Message",
            Send: "Send",
            SalesOrders:"Sales Orders",
            HelpWithYourAccount: "Help with your FE Direct Account",
            ProductSupport: "Product Support",
            WebsiteSupport: "Website Support",
            CustomerService: "Customer Service",
            GeneralInquiries: "General Inquiries",
            Sending: "Sending...",
            MessageSent: "Your message has been sent.",
            FailedToSendMessage: "Failed to send message. Please try again later."
        },

        pt: {
            EmailUs: "Envie-nos um e-mail",
            RequiredInformation: "Informa��es Necess�rias",
            SelectASubject: "Selecione um Assunto",
            Subject: "Assunto",
            Customer: "Cliente",
            CustomerNumber: "Numero do Cliente",
            FirstName: "Nome proprio",
            LastName: "Apelido",
            Email: "Email",
            Phone: "Numero de telefone",
            Extension: "Ramal Telefonico (Opcional)",
            Fax: "Fax (Opcional)",
            Message: "Mensagem",
            Send: "Enviar",
            SalesOrders:"Ordens de venda",
            HelpWithYourAccount: "Ajuda com a sua conta FE Direct",
            ProductSupport: "Suporte ao Produto",
            WebsiteSupport: "Suporte ao site",
            CustomerService: "Atendimento",
            GeneralInquiries: "Perguntas Gerais",
            Sending: "Envio...",
            MessageSent: "Sua mensagem foi enviada.",
            FailedToSendMessage: "Falha ao enviar mensagem. Por favor, tente novamente mais tarde."
        },

        es: {
            EmailUs: "Env�enos un correo electronico",
            RequiredInformation: "Informacion requerida",
            SelectASubject: "Seleccione un tema",
            Subject: "Asunto",
            Customer: "Cliente",
            CustomerNumber: "Numero de cliente",
            FirstName: "Nombre",
            LastName: "Apellido",
            Email: "Correo electronico",
            Phone: "Numero de telefono",
            Extension: "Extension (opcional)",
            Fax: "Fax (opcional)",
            Message: "Mensaje",
            Send: "Enviar",
            SalesOrders:"Ordenes de venta",
            HelpWithYourAccount: "Ayuda con su cuenta de Fe Direct",
            ProductSupport: "Soporte de producto",
            WebsiteSupport: "Soporte del sitio web",
            CustomerService: "Servicio al cliente",
            GeneralInquiries: "Consultas generales",
            Sending: "Env�o...",
            MessageSent: "Tu mensaje ha sido enviado.",
            FailedToSendMessage: "No se pudo enviar el mensaje. Por favor, int�ntelo de nuevo m�s tarde."
        },

        it: {
            EmailUs: "Inviaci un'e-mail",
            RequiredInformation: "Informazioni obbligatorie",
            SelectASubject: "Seleziona un argomento",
            Subject: "Soggetto",
            Customer: "Cliente",
            CustomerNumber: "Numero cliente (facoltativo)",
            FirstName: "Nome di battesimo",
            LastName: "Cognome",
            Email: "Email",
            Phone: "Numero di telefono",
            Extension: "Interno telefonico (opzionale)",
            Fax: "Fax (opzionale)",
            Message: "Messaggio",
            Send: "Invia",
            SalesOrders:"Ordini di vendita",
            HelpWithYourAccount: "Aiuto per il tuo account FE Direct",
            ProductSupport: "Supporto per i prodotti",
            WebsiteSupport: "Supporto per il sito web",
            CustomerService: "Servizio clienti",
            GeneralInquiries: "Richieste generali",
            Sending: "Invio...",
            MessageSent: "Il tuo messaggio � stato inviato",
            FailedToSendMessage: "Failed to send message. Please try again later."
        },

        setLanguage: LocalizedStrings.setLanguage,
    });

    localize() {
        this.localized.setLanguage(Localization.language);
        Localization.subscribe(() => this.localized.setLanguage(Localization.language));
        return this.localized;
    }
}

export default new HelpStrings();
