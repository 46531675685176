import React from "react";
import PropTypes from "prop-types";

const PlusIcon = ({ color }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M20 11.4286H11.4286V20H8.57143V11.4286H0V8.57143H8.57143V0H11.4286V8.57143H20V11.4286Z"
          fill={color}
        />
      </svg>
    </>
  );
};

PlusIcon.propTypes = {
  color: PropTypes.string,
};

PlusIcon.defaultProps = {
  color: "#000000",
};

export default PlusIcon;
