import Api from './Api';
import APIMSettingsService from './APIMSettingsService';

class ClaimService {

    fetchClaims = (state, props) => {

        let url = '/api/claims/getClaims';

        let claimsLookup = {
            selectedCustomer: props.selectedCustomer.id,
            statusDescription: state.status,
            numberDays: state.dateRange,
            pageSize: state.pageSize,
            page: state.page,

            searchValue: state.searchValue
        };

        return Api.fetch(url, {
            method: 'POST', body: JSON.stringify(claimsLookup)
        }).then(this.handleResponse);
    }

    fetchFastTrackStatus = (sku) => {
        let url = `/api/claims/${sku}/fastTrack`;

        return Api.fetch(url, {
            method: 'GET'
        })
    }

    fetchWarrantyItemStatus = (sku) => {
        let url = `/api/claims/checkItem/${sku}`;

        return Api.fetch(url, {
            method: 'GET'
        })
    }

    fetchWarrantySerialNumber = (serialNumber) => {
        let url = `/api/claims/checkSerialNumber/${serialNumber}`;

        return Api.fetch(url, { method: 'GET' })
            .then((res) => {
                return res;
            });
    }

    async claimApproval(claim, customerId) {
        let url = '/api/claims/claimApproval';
        const claimApprovalBody = JSON.stringify(claim);

        return Api.fetch(url, {
            method: 'POST',
            body: claimApprovalBody
        }).then(this.handleResponse);
    }

    async submitClaim(claim) {
        let url = '/api/claims/submitClaim';

        return Api.fetch(url, {
            method: 'POST',
            body: JSON.stringify(claim)
        }).then(this.handleResponse);
    }

    async uploadAttachment(files, caseNumber, customerNumber) {
        let url = '/api/claims/uploadAttachment';

        const formData = new FormData();
        formData.append("CaseNumber", caseNumber);
        formData.append("CustomerNumber", customerNumber);
        files.map(f => {
            formData.append("Files", f);
        })
        return Api.fetchFormFile(url, {
            method: 'POST',
            body: formData
        }).then(this.handleResponse);
    }

    //This API call to submit individual claim. Attachments need case number
    async submitClaimWithAttachment(claim) {
        let url = '/api/claims/submitClaimWithAttachment';

        let data = {
            claimLine: claim,
            customerNumber: claim.customerNumber
        }
        return Api.fetch(url, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(this.handleResponse);
    }

    async validateClaim(claim) {
        let url = '/api/claims/validateClaim';

        return Api.fetch(url, {
            method: 'POST',
            body: JSON.stringify(claim)
        }).then(this.handleResponse);
    }

    fetchClaimsStatus = (storefrontId) => {
        let url = `/api/claims/getClaimsStatus/${storefrontId}`;

        return Api.fetch(url, {
            method: 'GET'
        })
    }

    fetchDispositions = (storefrontId) => {
        return Api.fetch(`/api/claims/getDispositions/${storefrontId}`, {
            method: 'GET'
        })
    }

    fetchStatusCodes = () => {
        return Api.fetch(`/api/administrative/claimStatusCodes`, {
            method: 'GET'
        })
    }

    fetchPumpManufacturers = async () => {
        let url = `/api/claims/getPumpManufacturers`;

        return Api.fetch(url, {
            method: 'GET'
        });
    }

    handleResponse = (response) => {
        if (response) {
            return response.json();
        }
    }

}

export default new ClaimService();