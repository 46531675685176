import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import MenuIcon from './icons/Menu';
import CalendarIcon from './icons/CalendarIcon';
import NavLink from './NavLink';
import { NavHashLink as Link } from 'react-router-hash-link';
import User from './icons/User';
import InvoiceIcon from './icons/InvoiceIcon';
import CartIcon from './icons/CartIcon';
import Authorize from './Authorize';
import AccountIcon from './icons/Account';
import WarrantyLink from './WarrantyLink';
import QuoteIcon from './icons/QuoteIcon';
import * as Roles from '../resources/UserRole';
import { withRouter } from 'react-router-dom';
import Warranty from './icons/Warranty';
import { withStorefrontConfig } from '../hooks/StorefrontSettingsContext';

export class Navigation extends React.Component {

    constructor(props) {
        super(props);

        this.state = { menuIsOpen: false };
    }

    toggleMenu = () => {
        this.setState({
            menuIsOpen: !this.state.menuIsOpen
        });
    }

    async handleNavLinkClick(url) {
        try {
            if (!props) {
                await new Promise(resolve => setTimeout(resolve, 1000));
                this.props.history.push(url);
            }
        } catch (error) {
            console.error('Navigation error:', error);
        }
    }

    render() {
        const localized = this.props.StorefrontConfig.labels;
        return (
            <div className={`fele-navigation ${this.state.menuIsOpen ? "open" : ""}`} ref={node => this.node = node} data-cy="navigation">
                <div className="flex logo-toggle">
                    <button className="menu-toggle" onClick={this.toggleMenu}>
                        <MenuIcon />
                    </button>
                    <Link to="/">
                        <div className="icon logo"></div>
                    </Link>
                </div>
                <ul>
                    <NavLink
                        url="/orders"
                        caption={localized.OrderHistory}
                        icon={<CalendarIcon />}
                        onClick={() => this.handleNavLinkClick('/orders')}
                    />
                    <Authorize hasRoles={[Roles.VIEW_QUOTE_HISTORY]} displayMessage={false}>
                        <NavLink
                            url="/quotes"
                            caption={localized.QuoteHistory}
                            icon={<QuoteIcon />}
                            onClick={() => this.handleNavLinkClick('/quotes')}
                        />
                    </Authorize>
                    <Authorize hasRoles={[Roles.HAS_REPORTING]} displayMessage={false}>
                        <NavLink
                            url="/reporting"
                            caption={localized.Reporting}
                            icon={<InvoiceIcon />}
                            onClick={() => this.handleNavLinkClick('/reporting')}
                        />
                    </Authorize>
                    <Authorize hasRoles={[Roles.DEVELOPER, Roles.PLACE_ORDERS, Roles.PLACE_QUOTES]} displayMessage={false}>
                        <NavLink
                            url="/cart"
                            caption={localized.QuickAdd}
                            icon={<CartIcon />}
                            onClick={() => this.handleNavLinkClick('/cart')}
                        />
                    </Authorize>
                    <Authorize hasRoles={[Roles.VIEW_INVOICE_HISTORY]} displayMessage={false}>
                        <NavLink
                            url="/invoices"
                            caption={localized.Invoices}
                            icon={<InvoiceIcon />}
                            onClick={() => this.handleNavLinkClick('/invoices')}
                        />
                    </Authorize>
                    <Authorize hasRoles={[Roles.VIEW_ACCOUNT_BALANCE]} displayMessage={false}>
                        <NavLink
                            url="/account"
                            caption={localized.AccountBalance}
                            icon={<AccountIcon />}
                            onClick={() => this.handleNavLinkClick('/account')}
                        />
                    </Authorize>
                    <Authorize as={["Administrator", "CustomerAdministrator"]} displayMessage={false}>
                        <NavLink
                            url="/admin/users"
                            caption={localized.Users}
                            icon={<User />}
                            onClick={() => this.handleNavLinkClick('/admin/users')}
                        />
                    </Authorize>
                    <Authorize hasRoles={[Roles.HAS_LEGACY_WARRANTY_ACCESS]} displayMessage={false}>
                        <WarrantyLink />
                    </Authorize>
                    <Authorize hasRoles={[Roles.HAS_WARRANTY_ACCESS]} displayMessage={false}>
                        <NavLink
                            url="/claims"
                            caption={localized.WarrantyClaims}
                            icon={<Warranty />}
                            onClick={() => this.handleNavLinkClick('/claims')}
                        />
                    </Authorize>
                </ul>
            </div>
        );
    }
}

Navigation.propTypes = {
    currentUser: PropTypes.object,
    history: PropTypes.object.isRequired, // Ensure history prop is passed for navigation
}

function mapStateToProps(state) {
    return {
        currentUser: state.user.currentUser,
    };
}

export default compose(
    withRouter,
    connect(mapStateToProps)
)(withStorefrontConfig(Navigation));
