import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { Row, Col, Button } from "react-bootstrap";
import FormSection from "../form/FormSection";
import Notifications from "../../../services/Notifications";
import { withStorefrontConfig } from "../../../hooks/StorefrontSettingsContext";
import PropTypes from "prop-types";
import FilePreview from "./FilePreview";
import PlusIcon from "../../icons/PlusIcon";
import { CSSTransition } from "react-transition-group";

const validateFiles = (
  newFiles,
  existingFiles,
  {
    fileCountLimit,
    allowedExtensions,
    fileSizeLimit,
    maxFileNameLength,
    localized,
  }
) => {
  if (existingFiles.length + newFiles.length > fileCountLimit) {
    Notifications.warning(`${localized.FileCountLimit} ${fileCountLimit}`);
    return false;
  }

  for (let file of newFiles) {
    const isDuplicate = existingFiles.some(
      (existingFile) => existingFile.name === file.name
    );
    if (isDuplicate) {
      Notifications.error(`${localized.DuplicateFileName} ${file.name}`);
      return false;
    }

    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      Notifications.error(
        `${localized.SupportedAttachmentTypes} ${allowedExtensions}`
      );
      return false;
    }

    if (file.size > fileSizeLimit) {
      Notifications.error(localized.FileSizeLimit);
      return false;
    }

    if (file.name.length > maxFileNameLength) {
      Notifications.error(
        localized.MaxFileNameLengthMessage + maxFileNameLength
      );
      return false;
    }
  }

  return true;
};

const FileUpload = ({
  localized,
  isPioneerItem,
  uploadClaimAttachmentsToLocalClaimItem,
  StorefrontConfig,
  files: initialFiles = [],
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [files, setFiles] = useState(initialFiles);
  const fileInputRef = useRef(null);

  const config = useMemo(
    () => ({
      allowedExtensions:
        StorefrontConfig.settingsList.WarrantyAttachmentFileTypes.toString(),
      fileCountLimit: parseInt(StorefrontConfig.settingsList.FileCount),
      fileSizeLimit: parseInt(StorefrontConfig.settingsList.FileSize),
      maxFileNameLength: parseInt(
        StorefrontConfig.settingsList.MaxFileNameLength
      ),
    }),
    [StorefrontConfig]
  );

  useEffect(() => {
    setIsVisible(isPioneerItem);
  }, [isPioneerItem]);

  useEffect(() => {
    setFiles(initialFiles);
  }, [initialFiles]);

  const handleButtonClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleFileChange = useCallback(
    (event) => {
      const newFiles = Array.from(event.target.files);

      if (validateFiles(newFiles, files, { ...config, localized })) {
        const updatedFiles = [...files, ...newFiles];
        setFiles(updatedFiles);

        if (uploadClaimAttachmentsToLocalClaimItem) {
          uploadClaimAttachmentsToLocalClaimItem(updatedFiles);
        }

        fileInputRef.current.value = null;
      }
    },
    [files, config, localized, uploadClaimAttachmentsToLocalClaimItem]
  );

  const handleRemoveFile = useCallback(
    (fileName) => {
      const newFiles = files.filter((file) => file.name !== fileName);
      setFiles(newFiles);
      uploadClaimAttachmentsToLocalClaimItem(newFiles);
    },
    [files, uploadClaimAttachmentsToLocalClaimItem]
  );

  return (
    <CSSTransition
      in={isVisible}
      timeout={300}
      classNames="file-upload"
      unmountOnExit
    >
      <Row>
        <Col md={8}>
          <FormSection label={localized.Attachments} className="mt-4 mb-2">
            <div className="d-flex align-items-center gap-3 mt-2">
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
                aria-hidden="true"
              />
              <Button
                style={{ backgroundColor: "#D9D9D9", border: "none" }}
                className="p-3"
                onClick={handleButtonClick}
                aria-label={localized.Upload}
              >
                <PlusIcon />
              </Button>
              {files.map((file) => (
                <FilePreview
                  key={file.name}
                  file={file}
                  handleRemoveFile={handleRemoveFile}
                />
              ))}
            </div>
          </FormSection>
        </Col>
      </Row>
    </CSSTransition>
  );
};

FileUpload.propTypes = {
  uploadClaimAttachmentsToLocalClaimItem: PropTypes.func,
  localized: PropTypes.object,
  isPioneerItem: PropTypes.bool,
  StorefrontConfig: PropTypes.object,
  files: PropTypes.arrayOf(PropTypes.object),
};

export default withStorefrontConfig(FileUpload);
