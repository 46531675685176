import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import IdleTimer from 'react-idle-timer'
import AccountSummary from './components/account/AccountSummary';
import Api from './services/Api';
import Authorize from './components/Authorize';
import BrowserCheck from './components/BrowserCheck';
import CartService from './services/CartService';
import Configuration from './resources/Configuration'
import FeedbackButton from './components/buttons/FeedbackButton';
import { GoogleAnalytics } from './components/GoogleAnalytics';
import Home from './components/Home';
import Loading from './components/Loading';
import Localization from './services/Localization';
import MessageSystem from './components/MessageSystem';
import Navigation from './components/Navigation';
import Order from './components/orders/Order';
import Pingdom from './components/Pingdom';
import ProductSearchResults from './components/products/ProductSearchResults';
import ProductDetail from './components/products/ProductDetail';
import Quote from './components/quotes/Quote';
import EditQuote from './components/quotes/EditQuote';
import ShoppingCart from './components/cart/Cart';
import ReviewCart from './components/cart/CartReviewOrder';
import OrderPlaced from './components/cart/OrderPlaced';
import UserBar from './components/UserBar';
import Users from './components/users/Users';
import UsersFilter from './components/users/Filter';
import UsersService from './services/UserService';
import CustomerService from './services/Customer';
import * as userActions from './actions/userActions';
import * as cartActions from './actions/cartActions';
import * as settingsActions from './actions/settingsActions';
import * as Roles from './resources/UserRole';
import Auth from './services/Auth';
import OrderHistory from './components/OrderHistory';
import InvoiceHistory from './components/invoices/InvoiceHistory';
import ClaimHistory from './components/ClaimHistory';
import Welcome from './components/Welcome';
import { ToastContainer } from 'react-toastify';
//needed for FA bug: https://markoskon.com/fix-oversized-icons-from-react-fontawesome/
import { config } from "@fortawesome/fontawesome-svg-core";
import { NavHashLink as Link } from 'react-router-hash-link';
import { CustomerProvider } from './hooks/CustomerContext';
import { StorefrontSettingsProvider } from './hooks/StorefrontSettingsContext';
import { SalesHistoryConfigProvider } from './hooks/SalesHistoryConfigContext';
import { CartConfigProvider } from './hooks/CartConfigContext';
import { AccountSummaryConfigProvider } from './hooks/AccountSummaryConfigContext';
import { InvoiceConfigProvider } from './hooks/InvoiceConfigContext';
import { ProductConfigProvider } from './hooks/ProductConfigContext';
import { WarrantyConfigProvider } from './hooks/WarrantyConfigContext';
import { StateStorageConfigProvider } from './hooks/StateStorageConfigContext';
import { DashboardConfigProvider } from './hooks/DashboardConfigContext';
import { FeedbackConfigProvider } from './hooks/FeedbackConfigContext';
import { UserConfigProvider } from './hooks/UserConfigContext';
import Help from './components/Help';
import Reports from './components/reporting/Reports';
import Feedback from './components/Feedback';
config.autoAddCss = false;

export class App extends Component {
    constructor(props) {
        console.log(props)
        super(props);
        this.state = {
            user: null,
            isAuthorized: false,
            fetching: true,
            isNewUser: false,
            title: '',
            stylePath: '',
            typography: '',
            appleTouchIcon: '',
            favicon32x32: '',
            favicon16x16: '',
            siteJson: '',
            idleTimeout: 45,
            customerList: [],
            defaultCustomer: {},
           // languageLocale: "",
        }

        Localization.setLocale(navigator.language); // TODO: Remove after Data-driven updates.
        this.idleTimer = null;
        this.handleOnIdle = this.handleOnIdle.bind(this);
    }

    async componentDidMount() {
        await this.authorize();
        await this.setBranding();
    }

    setBranding = async () => {
        var environment = window.location.host.includes(Configuration.uriParts.devURISubdomainPart) ? "DEV - " : window.location.host.includes(Configuration.uriParts.pyURISubdomainPart) ? "PY - " : "";
        // Fueling Checks for now for styling.
        if (this.state.user && (this.state.user.storefrontNumber === "5000" || this.state.user.storefrontNumber === "5700")) {
            await this.setStyles(true);
            await this.setState({ title: environment + 'FFS PRO Advantage', typography: 'https://use.typekit.net/qif4igd.css', faviconPath: process.env.PUBLIC_URL + '/ffspro_favicon.ico', appleTouchIcon: '/icons/ffs_apple-touch-icon.png', favicon32x32: '/icons/ffs_favicon-32x32.png', favicon16x16: '/icons/ffs_favicon-16x16.png', siteJson: '/icons/ffs_site.json' });
        }
        else if (this.state.user && this.state.user.storefrontNumber !== "5000" && this.state.user.storefrontNumber !== "5700") {
            await this.setStyles(false);
            await this.setState({ title: environment + 'FE Direct', typography: 'https://cloud.typography.com/7166872/7263212/css/fonts.css', faviconPath: process.env.PUBLIC_URL + '/fed_favicon.ico', appleTouchIcon: '/icons/fed_apple-touch-icon.png', favicon32x32: '/icons/fed_favicon-32x32.png', favicon16x16: '/icons/fed_favicon-16x16.png', siteJson: '/icons/fed_site.json' });
        }
        else {
            await this.setStyles(false);
            await this.setState({ title: environment + 'FE Direct', typography: 'https://cloud.typography.com/7166872/7263212/css/fonts.css', faviconPath: process.env.PUBLIC_URL + '/fed_favicon.ico', appleTouchIcon: '/icons/fed_apple-touch-icon.png', favicon32x32: '/icons/fed_favicon-32x32.png', favicon16x16: '/icons/fed_favicon-16x16.png', siteJson: '/icons/fed_site.json' });
        }

    }

    setStyles = (fueling) => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            if (fueling) require('./design/styles/ffs-theme.scss'); else require('./design/styles/default-theme.scss');
        } else {
            let path = fueling ? '/css/fueling.bundle.min.css' : '/css/fele.bundle.min.css';
            this.setState({ stylePath: path });
        }
    }

    authorize = async () => {
        await Api.fetch("/api/user").then(this.loadUser).catch(this.unauthorized);
    }

    loadFrontendSettings = () => {
        Api.fetch("/api/settings/frontendSettings").then(this.handleloadFrontendSettingsResult);
    }

    handleloadFrontendSettingsResult = (result) => {
        this.props.settingsActions.loadFrontendSettings(result);
        if (result && result.idleTimeout) {
            this.setState({ idleTimeout: Number(result.idleTimeout) });
        }
    }

    loadUser = (user) => {
        var fuelingURI = "advantage-py.ffspro.com";
        var isFueling = window.location.host.includes(fuelingURI);
        if (user) {
            // load regular user
            // user storefront null condition for welcome screen
            if ((user.storefront != null) && (user.assignedCustomers?.length > 0 || user.personaName == "Administrator")) {
                UsersService.setCurrent(user); // TODO: Remove this once everything has been redux-ified
                this.props.userActions.ReceivedCurrentUser(user);
                this.setState({ isAuthorized: true, user: user, isNewUser: false });
                this.setBranding();
                // Fetch customers for user
                CustomerService.fetchCustomers()
                    .then(res => {
                        this.props.userActions.ReceivedCustomers(res);
                        const storeCustomer = CustomerService.storedSelectedCustomer();
                        this.setState({ fetching: false, customerList: res, defaultCustomer: storeCustomer });
                    });

                this.loadFrontendSettings();
                CartService.load(user.id).then((cart) => {
                    if (cart) {
                        this.props.cartActions.OrderLoaded(cart);
                    }
                    else {
                        console.log("Shopping Cart not found.");
                    }
                });
            }
            else if ((user.email != null && !isFueling)) {
                UsersService.setCurrent(user);
                this.props.userActions.ReceivedCurrentUser(user);
                this.setState({ fetching: false, isNewUser: true });
            }
        }
        else {
            this.setState({ fetching: false, isNewUser: false });
        }
    }

    unauthorized = (e) => {
        this.setState({ fetching: false });
    }

    redirect = (url) => {
        return (<Redirect to={url} />);
    }

    locationHasState = (props) => {
        return (props.location && props.location.state);
    }

    productSearch = (props) => {
        return (<div style={{ padding: "2.5rem" }}><ProductSearchResults sku={props.match.params.sku} page={props.match.params.page} /></div>);
    }

    productDetail = (props) => {
        return (<ProductDetail sku={props.match.params.sku} />);
    }

    home = () => {
        return (<Home isFueling={this.state.user && (this.state.user.storefrontNumber === "5000" || this.state.user.storefrontNumber === "5700")} userEmail={this.state.user.email} />);
    }

    welcome = () => {
        return (<Welcome />);
    }

    orderHistory = () => {
        return (
            <div style={{ padding: "2.5rem" }}><OrderHistory isQuote={false} /></div>
        );
    }

    claimHistory = (props) => {
        return (
            <div style={{ padding: "2.5rem" }}><ClaimHistory /></div>
        );
    }

    orderDetails = (props) => {
        let params = props.match.params;
        return (<Order orderId={params.orderId} orderCompany={params.orderCompany} docType={params.docType} />);
    }

    quoteHistory = () => {
        return (
            <div style={{ padding: "2.5rem" }}><OrderHistory isQuote={true} /></div>
        );
    }

    quoteDetails = (props) => {
        let params = props.match.params;
        return (<Quote orderId={params.orderId} orderCompany={params.orderCompany} docType={params.docType} />);
    }

    editQuote = (props) => {
        return (<EditQuote externalId={props.match.params.externalId} />);
    }

    invoices = () => {
        return (
            <Authorize hasRoles={[Roles.VIEW_INVOICE_HISTORY]} displayMessage={true}>
                <div style={{ padding: "2.5rem" }} ><InvoiceHistory /></div>
            </Authorize>
        )
    }

    users = () => {
        return (
            <Authorize as={["Administrator", "CustomerAdministrator"]} displayMessage={true}>
                <div style={{ padding: "2.5rem" }} >
                    <UsersFilter />
                    <Users />
                </div>
            </Authorize>
        )
    }

    cart = () => {
        return (
            <Authorize hasRoles={[Roles.DEVELOPER, Roles.PLACE_ORDERS, Roles.PLACE_QUOTES]} displayMessage={true}>
                <ShoppingCart />
            </Authorize>
        )
    }

    feedback = () => {
        return (            
                <Feedback />
        )
    }

    help = () => {
        return (
            <Help />
        )
    }

    reviewCart = (props) => {
        return (
            <Authorize hasRoles={[Roles.DEVELOPER, Roles.PLACE_ORDERS, Roles.PLACE_QUOTES]} displayMessage={true}>
                <ReviewCart {...props} />
            </Authorize>
        )
    }

    orderConfirmation = (props) => {
        return (
            <Authorize hasRoles={[Roles.DEVELOPER, Roles.PLACE_ORDERS, Roles.PLACE_QUOTES]} displayMessage={true}>
                <OrderPlaced IsQuote={props.location.state.IsQuote} DocumentNumber={props.location.state.DocumentNumber} />
            </Authorize>
        )
    }

    account = () => {
        return (
            <Authorize hasRoles={[Roles.VIEW_ACCOUNT_BALANCE]} displayMessage={true}>
                <div style={{ padding: "2.5rem" }} ><AccountSummary /></div>
            </Authorize>
        )
    }

    reporting = () => {
        return (
            <div style={{ padding: "2.5rem" }} > <Reports user={this.state.user} /></div>
        )
    }

    handleOnIdle() {
        Auth.signOut();
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>{this.state.title}</title>
                    <link rel="stylesheet" type="text/css" href={this.state.stylePath}></link>
                    <link rel="stylesheet" type="text/css" href={this.state.typography}></link>
                    <link rel="stylesheet" href="https://use.typekit.net/qif4igd.css"></link>
                    <link rel="apple-touch-icon" sizes="180x180" href={this.state.appleTouchIcon}></link>
                    <link rel="icon" type="image/png" sizes="32x32" href={this.state.favicon32x32}></link>
                    <link rel="icon" type="image/png" sizes="16x16" href={this.state.favicon16x16}></link>
                    <link rel="manifest" href={this.state.siteJson}></link>
                </Helmet>
                <Router>

                    <Pingdom />
                    <IdleTimer
                        ref={ref => { this.idleTimer = ref }}
                        timeout={1000 * 60 * this.state.idleTimeout}
                        onIdle={this.handleOnIdle}
                    />
                    <div className="flex">
                        {
                            this.state.fetching &&
                            <Loading type="brand" />
                        }
                        {
                            !this.state.fetching && this.state.isNewUser &&
                            <div className="unauthorized">
                                <div className={`fele-navigation ${this.state.menuIsOpen ? "open" : ""}`} ref={node => this.node = node} data-cy="navigation">
                                    <div className="flex logo-toggle">
                                        <button className="menu-toggle" onClick={this.toggleMenu}>
                                        </button>
                                        <Link to="/">
                                            <div className="icon logo"></div>
                                        </Link>

                                    </div>
                                </div>
                                <Route exact path='/' render={this.welcome} />
                            </div>
                        }
                        {
                            !this.state.fetching && !this.state.isAuthorized && !this.state.isNewUser &&
                            <div className="unauthorized">
                                <MessageSystem title="Permission Denied" message="You currently do not have access to this application.  To request access, contact your administrator." />
                            </div>
                        }
                        {
                            !this.state.fetching && this.state.isAuthorized &&
                            <React.Fragment>
                                    <StorefrontSettingsProvider storefrontNumber={this.state.user.storefrontNumber} locale={this.state.user.locale == null ? 'en_US' : this.state.user.locale} storefront={this.state.user.storefront}>
                                        <StateStorageConfigProvider>
                                            <GoogleAnalytics>
                                                <CustomerProvider customers={this.state.customerList} defaultCustomer={this.state.defaultCustomer }>
                                            <BrowserCheck />
                                            <Navigation />
                                            <UserBar />
                                            <div className="fele-dashboard">
                                                        <ToastContainer />
                                                <DashboardConfigProvider storefrontNumber={this.state.user.storefrontNumber}>
                                                    <Route exact path='/' render={this.home} />
                                                </DashboardConfigProvider>
                                                <SalesHistoryConfigProvider storefrontNumber={this.state.user.storefrontNumber}>
                                                    <Route exact path='/orders' render={this.orderHistory} />
                                                    <Route exact path='/order/:orderId/:orderCompany/:docType' render={this.orderDetails} />

                                                    <Route exact path='/quotes' render={this.quoteHistory} />
                                                    <Route exact path='/quote/:orderId/:orderCompany/:docType' render={this.quoteDetails} />

                                                    <Route path='/reporting' render={this.reporting} />
                                                </SalesHistoryConfigProvider>
                                                    <ProductConfigProvider storefrontNumber={this.state.user.storefrontNumber}>
                                                        <CartConfigProvider storefrontNumber={this.state.user.storefrontNumber} local={this.state.locale}>
                                                            <Route exact path='/products/:sku/page/:page' render={this.productSearch} />
                                                            <Route exact path='/product/:sku' render={this.productDetail} />                                                    
                                                            <Route exact path='/cart' render={this.cart} />
                                                            <Route exact path='/cart/review' render={this.reviewCart} />
                                                            <Route exact path='/cart/confirmation' render={this.orderConfirmation} />
                                                            <Route exact path='/quote/edit/:externalId' render={this.editQuote} />
                                                    </CartConfigProvider>
                                                </ProductConfigProvider>
                                                <UserConfigProvider storefrontNumber={this.state.user.storefrontNumber}>
                                                    <Route exact path='/admin/users' render={this.users} />
                                                </UserConfigProvider>
                                                <InvoiceConfigProvider storefrontNumber={this.state.user.storefrontNumber}>
                                                    <Route exact path='/invoices' render={this.invoices} />
                                                </InvoiceConfigProvider>

                                                <AccountSummaryConfigProvider storefrontNumber={this.state.user.storefrontNumber}>
                                                    <Route exact path='/account' render={this.account} />
                                                </AccountSummaryConfigProvider>

                                                <WarrantyConfigProvider storefrontNumber={this.state.user.storefrontNumber}>
                                                    <Route exact path='/claims' render={this.claimHistory} />
                                                </WarrantyConfigProvider>

                                                        <Route exact path='/help' render={this.help} />

                                                <FeedbackConfigProvider storefrontNumber={this.state.user.storefrontNumber}>
                                                            <Route exact path='/feedback' render={this.feedback} />
                                                </FeedbackConfigProvider>
                                            </div>
                                            <Authorize hasRoles={[Roles.SUMBIT_FEEDBACK]} displayMessage={false}>
                                                <FeedbackButton collapseInMs={8000} />
                                            </Authorize>
                                        </CustomerProvider>
                                            </GoogleAnalytics>
                                        </StateStorageConfigProvider>
                                        </ StorefrontSettingsProvider>
                            </React.Fragment>
                        }
                    </div>
                </Router>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        cartActions: bindActionCreators(cartActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
        settingsActions: bindActionCreators(settingsActions, dispatch),
    };
}

export default connect(
    null,
    mapDispatchToProps
)(App);