import React from "react";
import PropTypes from "prop-types";

const CommentSection = ({
  localized,
  stringLimittext,
  commentsSubmit,
  commentsError,
  needsValidation,
  handleEnter,
  commentHandleChange,
}) => {
  const capitalizeFirstChar = (str) => {
    if (str.length === 0) {
      return str;
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div className="claim-search">
      <div className="label">
        <div>{localized.Comments}</div>
      </div>

      <div className="fele-content">
        <div className="input-wrapper">
          <input
            className={`form-control ${
              needsValidation
                ? commentsError || commentsSubmit.length < 1
                  ? "is-invalid"
                  : "is-valid"
                : ""
            }`}
            data-cy="comments-submit"
            type="text"
            onChange={commentHandleChange}
            onKeyUp={handleEnter}
            value={commentsSubmit}
            maxLength={30}
            required
          />
        </div>
      </div>

      <div
        className="d-flex justify-content-end mt-1"
        style={{ fontSize: "1.4rem" }}
      >
        {stringLimittext} {capitalizeFirstChar(localized.charCount)}
      </div>
    </div>
  );
};

CommentSection.propTypes = {
  localized: PropTypes.object,
  stringLimittext: PropTypes.number,
  commentsSubmit: PropTypes.string,
  commentsError: PropTypes.bool,
  needsValidation: PropTypes.bool,
  handleEnter: PropTypes.func,
  commentHandleChange: PropTypes.func,
};

export default CommentSection;
