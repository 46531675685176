import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const InfoHover = (props) => {
  const msg = props.toolTipMsg;
  const id = props.id || "info-tooltip";

  if (!msg | _.isEmpty(msg)) {
    return null;
  }

  return (
    <>
      <OverlayTrigger
        placement="right"
        delay={{ hide: 660, show: 300 }}
        overlay={(props) => (
          <Tooltip id={id} {...props}>
            {msg}
          </Tooltip>
        )}
      >
        <FontAwesomeIcon
          icon={faInfoCircle}
          style={{ color: "#004cff" }}
          className="fa-2xs"
        />
      </OverlayTrigger>
    </>
  );
};

InfoHover.propTypes = {
  toolTipMsg: PropTypes.string,
  iconType: PropTypes.string,
};
export default InfoHover;
