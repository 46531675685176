import React from "react";
import PropTypes from "prop-types";

const ClaimTypeSelector = ({
  localized,
  returnPolicy,
  obsolete,
  isPioneerItem,
  itemDispositions,
  claimType,
  setClaimType,
  fetching,
  canAdd,
  fetchingAsync,
  needsValidation,
  claimTypeOptionError,
}) => {
  const contentStyle =
    fetching || fetchingAsync || canAdd === false
      ? { pointerEvents: "none", opacity: "0.4" }
      : {};

  const needsValidationStyle = needsValidation
    ? claimTypeOptionError || claimType === ""
      ? "is-invalid"
      : "is-valid"
    : "";

  return (
    <div className="claim-replacement-option">
      <div className="label">{localized.Type}*</div>

      <div className="fele-content" style={contentStyle}>
        {returnPolicy === "F" && obsolete ? (
          <input
            type="text"
            className="form-control"
            value={localized.CreditOnly}
            disabled
          />
        ) : isPioneerItem ? (
          <input
            type="text"
            className="form-control"
            value={localized.AnalysisOnly}
            disabled
          />
        ) : (
          <select
            data-cy="dispositionOption-submit"
            style={{ lineHeight: 2.2, fontSize: "small" }}
            onChange={(e) => setClaimType(e)}
            value={claimType ? claimType.value : ""}
            className={`form-select ${needsValidationStyle}`}
            required
            disabled={
              fetching ||
              fetchingAsync ||
              canAdd === false ||
              itemDispositions.length === 0
            }
            tabIndex={fetching || fetchingAsync || canAdd === false ? -1 : 0}
          >
            <option value="">{localized.Select}</option>
            {itemDispositions.length > 0 &&
              itemDispositions.map((disposition, index) => (
                <option key={index} value={disposition.id}>
                  {" "}
                  {disposition.description}
                </option>
              ))}
          </select>
        )}
      </div>
    </div>
  );
};

ClaimTypeSelector.propTypes = {
  localized: PropTypes.object,
  obsolete: PropTypes.bool,
  isPioneerItem: PropTypes.bool,
  setClaimType: PropTypes.func,
  fetching: PropTypes.bool,
  canAdd: PropTypes.bool,
  fetchingAsync: PropTypes.bool,
  needsValidation: PropTypes.bool,
  claimTypeOptionError: PropTypes.bool,
};

export default ClaimTypeSelector;
