import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';

import storefrontService from '../services/Storefront';
import { useStorefrontSettings } from './StorefrontSettingsContext';
import Localization from '../services/Localization';
import * as Components from '../resources/Components';

const UserConfigContext = React.createContext();

function UserConfigProvider({ children, ...props }) {

    // have all the config settings here.
    const [userConfigLoaded, setUserConfigLoaded] = useState(false);
    const [settings, setSettings] = useState(props.settings || []);
    const [labels, setLabels] = useState({});
    const { locale } = useStorefrontSettings();
    const [childComponents, setChildComponents] = useState(props.childComponents || {});
  
    // might have to just make this a parameter that gets passed in
    const storefrontNumber = ('00000' + props.storefrontNumber).slice(-5);

    useEffect(() => {
        getUserConfigSettings();
    }, []);

    useEffect(() => {
        Localization.getComponentLabels(Components.USERS, locale, storefrontNumber)
            .then(res => {
                setLabels(res);
            })
    }, [locale]);

    // fetchSettings
    const getUserConfigSettings = () => {
        storefrontService.getUsersSettings(storefrontNumber).then((res) => {
            setSettings(res.settings || []);
            setChildComponents(res.childComponents || []);
            setUserConfigLoaded(true);
        });
    }

    return (
        <UserConfigContext.Provider
            value={{
                settings,
                labels,
                childComponents,
                userConfigLoaded,
            }}
        >
            {children}
        </UserConfigContext.Provider >
    );
}

function useUserConfig() {
    const context = React.useContext(UserConfigContext);
    if (context === undefined) {
        throw new Error('UserConfigContext must be used within a UserConfigContext');
    }
    return context;
}

function withUserConfig(Component) {
    return function WrappedComponent(props) {
        const UserConfig = useUserConfig();
        return <Component {...props}
            UserConfig={UserConfig}
        />;
    }
}

export { UserConfigProvider, useUserConfig, withUserConfig }