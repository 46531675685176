import { React, useState, useEffect } from "react";
import { withWarrantyConfig } from "../../hooks/WarrantyConfigContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import ClaimAttachment from "../claims/ClaimAttachment";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import PenIcon from "../icons/PenIcon";

const ClaimLine = (props) => {
  const { labels: localized } = props.WarrantyConfig;
  const [collapsed, setCollapsed] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(props.claimLine.uploadFiles.length);
  }, [props.claimLine.uploadFiles]);

  const setShowModal = () => {
    setCollapsed(!collapsed);
  };

  const open = () => {
    setShowModal();
  };

  const { claimLine, uploadFiles, removeClaimItem, loadClaimLineItemForEdit } =
    props;

  return (
    <div className="d-flex justify-content-between text-start mb-2">
      <div>
        <p className="p-0 m-0">
          <strong>{localized.Item}</strong>:{" "}
          <span style={{ fontSize: "2rem" }}>{claimLine.itemNumber}</span>
        </p>

        <p className="p-0 m-0">
          <strong>{localized.SerialText}</strong>: {claimLine.serialNumber}
        </p>
      </div>

      <div className="d-flex flex-column align-items-start gap-1">
        <div className="d-flex align-items-center gap-2">
          <IconButton
            aria-label={localized.Edit}
            style={{ marginLeft: "-8px" }}
            onClick={() => loadClaimLineItemForEdit(claimLine.serialNumber)}
          >
            <PenIcon />
          </IconButton>

          <IconButton
            aria-label={localized.RemoveItem}
            onClick={() => removeClaimItem(claimLine.serialNumber)}
          >
            <DeleteOutline sx={{ fontSize: "21px", color: "black" }} />
          </IconButton>
        </div>

        {claimLine.isPioneerItem ? (
          <>
            <span className="d-flex text-black relative">
              <FontAwesomeIcon
                className="paperclip-icon"
                icon={faPaperclip}
                onClick={open}
              />
              <span
                className="fw-bold"
                style={{ position: "relative", bottom: "-1px" }}
              >
                {`x${count}`}
              </span>
            </span>

            <ClaimAttachment
              localized={localized}
              uploadFiles={uploadFiles}
              collapsed={collapsed}
              claimLine={claimLine}
              setShowModal={setShowModal}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

ClaimLine.propTypes = {
  uploadFiles: PropTypes.func,
  filesCount: PropTypes.func,
};

export default withWarrantyConfig(ClaimLine);
