import React from 'react';
import PropTypes from 'prop-types';
import CartItem from './CartItem';

export default class CartItems extends React.Component {
    constructor(props) {
        super(props);

    }
    calculateBranchVerificationItems = (itemId) => {
        if (this.props.branchVerificationItems && this.props.branchVerificationItems.length > 0) {
            if (this.props.branchVerificationItems.some(v => v === itemId)) {
                return true;
            }
            else {
                return false;
            }
        }
    }
    render() {
        
        if (!this.props.items || this.props.items.length < 0)
            return null;
        return (
            <div className="fele-cart-items">
                {
                    this.props.items.map((item, key) => (
                        <CartItem
                            key={key}
                            item={item}
                            isUnavailableAtBranch={this.calculateBranchVerificationItems(item.product.sku)}
                            itemId={item.id}
                            currentUser={this.props.currentUser}
                            selectedCustomer={this.props.selectedCustomer}
                            dateFormat={this.props.dateFormat}
                            removeItem={this.props.removeItem}
                            updateCartItem={this.props.updateCartItem}
                            updateLineItemQuantity={this.props.updateLineItemQuantity}
                            pCDiscountCodes={this.props.pCDiscountCodes}
                            headerRequestDate={this.props.headerRequestDate}
                            repriceCart={this.props.repriceCart}
                            calculating={this.props.calculating}
                        />
                    ))
                }
            </div>
        )
    }
}

CartItems.propTypes = {
    selectedCustomer: PropTypes.object,
    calculating: PropTypes.bool,
    currentUser: PropTypes.object,
    dateFormat: PropTypes.string,
    items: PropTypes.array,
    branchVerificationItems: PropTypes.array,
    updateLineItemQuantity: PropTypes.func,
    removeItem: PropTypes.func,
    updateCartItem: PropTypes.func,
    components: PropTypes.object,
    pCDiscountCodes: PropTypes.object,
    headerRequestDate: PropTypes.string
};