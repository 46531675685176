import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

const FileDownloadButton = ({
  downloadUrl,
  text,
  showIcon,
  visible,
  transition,
}) => {
  const transitionStyle = transition
    ? "opacity 1s ease, visibility 1s ease"
        : "";

  return (
    <div
      className="button-wrapper"
      style={{
        visibility: visible ? "visible" : "hidden",
        opacity: visible ? 1 : 0,
        transition: transitionStyle,
      }}
    >
      <a
        className="button button-alt text-nowrap px-4 text-center"
        target="_blank"
        rel="noopener noreferrer"
        href={downloadUrl}
        style={{ paddingTop: 5, textDecoration: "none" }}
      >
        {showIcon ? (
          <FontAwesomeIcon icon={faDownload} className="me-2" />
        ) : null}
        {` ${text}`}
      </a>
    </div>
  );
};

FileDownloadButton.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string,
  showIcon: PropTypes.bool,
  visble: PropTypes.bool,
  transition: PropTypes.bool,
};

FileDownloadButton.defaultProps = {
  showIcon: true,
  visble: true,
  transition: true,
};

export default FileDownloadButton;
