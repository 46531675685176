import DateHelper from './DateHelper';

export default class PricingHelper {
    static getNetSubtotal(items) {
        let sum = 0;
        if (items) {
            items.forEach(item => {
                if (!item.isCancelled) {
                   sum = sum + (item.netPrice * item.quantity);
                }
            });
        }
        return sum;
    }

    static getIncoTerms(netTotal) {

        let incoTerm = {};
        if (netTotal > 0 && (netTotal <= 149999)) {
            incoTerm = {
                key: "EXW",
                value: "EXW - EX WORKS"
            }
        }
        else {
            incoTerm = {
                key: "CPT",
                value: "CPT - CARRIAGE PAID TO"              
            }
        }
        return incoTerm;
    }

    static getNetCurrency(items) {
        let currency;
        if (items && items.length > 0) {
            currency = items[0].netPriceCurrency;
        }
        return currency || "";
    }
    static fetchLocale(country) {
        return getCountryCode(country.toLowerCase());
    }

    static mapPricedItems(currentItems, pricedItems, headerRequestedDate) {
        for (let i = 0; i < currentItems.length; i++) {

            currentItems[i] = updateCurrentItem(currentItems[i], pricedItems, currentItems[i].requestedDeliveryDate ? currentItems[i].requestedDeliveryDate :headerRequestedDate);
        }
        return currentItems;
    }
    static formatValue(country, currency, value) {
        const countryCode = getCountryCode(country);

        return new Intl.NumberFormat(countryCode, {
            style: 'currency',
            currency: currency.trim(),
            minimumFractionDigits: 2,
        }).format(value || 0.00);
    }
    static formatNumber(country, value) {
        const countryCode = getCountryCode(country);
        return new Intl.NumberFormat(countryCode, {
            minimumFractionDigits: 2,
        }).format(value || 0.00);
    }

    static countItems(items) {
        if (items && items.length > 0) {
            return items.reduce((count, item) => count + item.quantity, 0);
        }
        return 0;
    }

    static isItemObsolete(item) {
        return item.stockingType && item.stockingType.trim() === "O" ? true : false;
    }

    static isItemConfigured(item) {
        return item.stockingType && item.stockingType.trim() === "C" ? true : false;
    }
}

function getCountryCode(country) {
    // https://lonewolfonline.net/list-net-culture-country-codes/
    let countryCode;
    switch (country.toLowerCase()) {
        case "br":
            countryCode = "pt-BR";
            break;
        case "ca":
            countryCode = "en-CA";
            break;
        case "mx":
            countryCode = "es-MX";
            break;
        default:
            countryCode = "en-US";
            break;
    }
    return countryCode;
}

function updateCurrentItem(currentItem, pricedItems, headerRequestedDate) {
    let matchedItemIndex = -1;
    for (let i = 0; i < pricedItems.length; i++) {
        if (isItemToUpdate(currentItem, pricedItems[i], headerRequestedDate) && !currentItem.isCancelled) {
            matchedItemIndex = i;
            currentItem.netPrice = pricedItems[i].price;
            currentItem.availability = pricedItems[i].availability;
            currentItem.netPriceCurrency = pricedItems[i].currency;
            currentItem.quantityAvailable = pricedItems[i].quantityAvailable;
            currentItem.branchStockingType = pricedItems[i].branchStockingType;
            break;
        }
    }
    if (matchedItemIndex !== -1) {
        pricedItems.splice(matchedItemIndex, 1);
    }
    return currentItem;
}

function isItemToUpdate(currentItem, pricedItem, headerRequestedDate) {
    if (!DateHelper.isValidDate(currentItem.requestedDeliveryDate) && currentItem.requestedDeliveryDate) {
        currentItem.requestedDeliveryDate = new Date(headerRequestedDate);
    }

    return (pricedItem.sku === currentItem.product.sku &&
        //pricedItem.quantity === currentItem.quantity &&
        valuesAreEqual(pricedItem.pC1DiscountCodeId, currentItem.pc1DiscountCodeId) &&
        valuesAreEqual(pricedItem.pC2DiscountCodeId, currentItem.pc2DiscountCodeId) &&
        valuesAreEqual(pricedItem.pC3DiscountCodeId, currentItem.pc3DiscountCodeId)
    )
}

function valuesAreEqual(value1, value2) {
    return ((!value1 && !value2) || (value1 === value2));
}