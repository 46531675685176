import React, { useState, useEffect } from 'react';
import Api from '../services/Api';
import Notifications from "../services/Notifications";
import { withSelectedCustomer } from '../hooks/withSelectedCustomer';
import { withStorefrontConfig } from '../hooks/StorefrontSettingsContext';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useFeedbackConfig, withFeedbackConfig } from '../hooks/FeedbackConfigContext';
import Strings from './Welcome.Strings';

const requiredFields = ['Company','Message'];

const Feedback = (props) => {
    const store = props.store ? props.store : useSelector((state) => state);
    const storefrontId = props.StorefrontConfig.storefront.id;
    const feedbackLabels = useFeedbackConfig().labels;
    const user = store.user.currentUser;
    const customerId = props.selectedCustomer.id;
    const defaultValues = {
        Company: '',
        UsesAvailability: false,
        UsesPricing: false,
        UsesOrdering: false,
        UsesInvoicing: false,
        UsesReporting: false,
        UsesOther:false,
        OtherUsed: '',
        IsSatisfied: true,
        MostLiked: '',
        SuggestedImprovements: '',
        StorefrontId: storefrontId,
        CustomerId: customerId

    };

    const [formValues, setFormValues] = useState(defaultValues);

    const [sendingEmail, setSendingEmail] = useState(false);

    const setValue = e => {
        setFormValues({ ...formValues, [e.target.attributes.field.value]: e.target.value });
    }

    const handleRadioChange = (e) => {
        const { value, checked } = e.target;

        console.log(`${value} is ${checked}`);
        if (value === "Yes" && checked) {
            setFormValues({ ...formValues, IsSatisfied: true });
        }
        else {
            setFormValues({ ...formValues, IsSatisfied: false });
        }
    }

    const checkRequiredValues = () => {
        let i = 0;
        while (i < requiredFields.length) {
            if (formValues[requiredFields[i]] === '') {
                Notifications.error(feedbackLabels.SomethingMissing);
                return false;
            }
            if (formValues.OtherUsed === "" && !formValues.UsesAvailability && !formValues.UsesInvoicing && !formValues.UsesOrdering && !formValues.UsesPricing && !formValues.UsesReporting) {
                Notifications.error(feedbackLabels.MustChooseCase);
                return false
            }
            i++;
        }
        return true;
    }

    const handleError = e => {
        Notifications.error(e);
        setSendingEmail(false);
    }

    const handleSuccess = e => {
        Notifications.message(feedbackLabels.FeedbackMessageSent);
        setSendingEmail(false);
        history.back();
    }

    const handleFail = () => {
        const localized = Strings.localize();
        Notifications.error(localized.FailedToSendMessage);
        setSendingEmail(false);
    }

    const handleCheckBoxChange = (e) => {
        const { value, checked } = e.target;

        console.log(`${value} is ${checked}`);
        if (checked) {
            setFormValues({ ...formValues, [value]: true });
        }
        else {
            setFormValues({ ...formValues, [value]: false });
        }
    };

    const sendEmail = async (feedbackData) => {
        return await Api.fetch("api/user/SendFeedbackEmail", {
            body: JSON.stringify(feedbackData),
            method: 'POST'
        });
    }

    const submit = async () => {
        if (checkRequiredValues()) {
            const feedbackData = {
                Company: formValues.Company,
                UsesAvailability: formValues.UsesAvailability,
                UsesPricing: formValues.UsesPricing,
                UsesOrdering: formValues.UsesOrdering,
                UsesInvoicing: formValues.UsesInvoicing,
                UsesReporting: formValues.UsesReporting,
                OtherUsed: formValues.OtherUsed,
                IsSatisfied: formValues.IsSatisfied,
                MostLiked: formValues.MostLiked,
                SuggestedImprovements: formValues.SuggestedImprovements,
                StorefrontId: storefrontId,
                CustomerId: customerId,
                StorefrontNumber: user.storefront.number

            }

            setSendingEmail(true);

            try {

                const response = await sendEmail(feedbackData);
                const isSuccess = await response.json();

                if (!isSuccess) {
                    handleFail();

                    return;
                }

                handleSuccess(isSuccess);
            } catch (error) {
                handleError(error);
            }
        }
    }

    return (
        <div className="help-container">
            <div className="help-header">{feedbackLabels.SubmitFeedback}</div>
            <div className="help-form-container">
                <div className="feedback-label" >* {feedbackLabels.FeedbackCompany}</div>
                <div className="help-row" >
                    <input className="help-input" type="text" field={'Company'} onChange={setValue} value={formValues.Company} />
                </div>
                <div className="feedback-label" >* {feedbackLabels.WhichFeatures}</div>
                <form>
                    <div>
                        <div style={{ display: "flex", paddingLeft:"30px", paddingTop:"5px" }}>
                            <div>
                                <div>
                                    <input
                                        type="checkbox"
                                        value="UsesAvailability"
                                        id="availability"
                                        onChange={handleCheckBoxChange}
                                    />
                                    <label
                                           htmlFor="availability"
                                           style={{ paddingLeft: "3px" }}
                                    >
                                        {feedbackLabels.FeedbackAvailability}
                                    </label>
                                </div>
                                <div>
                                    <input
                                        type="checkbox"
                                        value="UsesPricing"
                                        id="pricing"
                                        onChange={handleCheckBoxChange}
                                    />
                                    <label
                                           htmlFor="pricing"
                                           style={{ paddingLeft: "3px" }}
                                    >
                                        {feedbackLabels.FeedbackPricing}
                                    </label>
                                </div>
                                <div>
                                    <input
                                        type="checkbox"
                                        value="UsesOrdering"
                                        id="ordering"
                                        onChange={
                                            handleCheckBoxChange
                                        }
                                    />
                                    <label
                                            htmlFor="ordering"
                                            style={{ paddingLeft: "3px" }}
                                    >
                                        {feedbackLabels.FeedbackOrdering}
                                    </label>
                                </div>
                                </div>
                                <div style={{ paddingLeft: "30px" }}>
                                <div>
                                    <input
                                        type="checkbox"
                                        value="UsesInvoicing"
                                        id="invoicing"
                                        onChange={
                                            handleCheckBoxChange
                                        }
                                    />
                                    <label
                                            htmlFor="invoicing"
                                            style={{ paddingLeft: "3px" }}
                                    >
                                        {feedbackLabels.FeedbackInvoicing}
                                    </label>
                                </div>
                                <div >
                                    <input
                                        type="checkbox"
                                        value="UsesReporting"
                                        id="reporting"
                                        onChange={
                                            handleCheckBoxChange
                                        }
                                    />
                                    <label
                                            htmlFor="reporting"
                                            style={{ paddingLeft: "3px" }}
                                    >
                                        {feedbackLabels.FeedbackReporting}
                                    </label>
                                </div>
                                <div >
                                    <input
                                        type="checkbox"
                                        value="UsesOther"
                                        id="other"
                                        onChange={
                                            handleCheckBoxChange
                                        }
                                    />
                                    <label
                                            htmlFor="other"
                                            style={{ paddingLeft: "3px" }}
                                    >
                                        {feedbackLabels.FeedbackOther}
                                    </label>
                                </div>
                            </div>
                        </div>
                        {formValues.UsesOther &&
                            <div className="help-row">
                                <textarea
                                    className="help-input"
                                    type="textarea"
                                    id="otherText"
                                    onChange={setValue}
                                    field={'OtherUsed'}
                                    value={formValues.OtherUsed}
                                    maxLength={250}
                                />
                            </div>
                        }
                    </div>
                </form>
                <div className="feedback-label" >{feedbackLabels.AreYouSatisfied}</div>
                <form style={{paddingTop:"1rem"} }>
                    <div style={{ display: "flex", paddingLeft:"30px" }}>
                        <div className="radio">
                            <label>
                                <input type="radio"
                                    value="Yes"
                                    checked={formValues.IsSatisfied===true}
                                    onChange={handleRadioChange} />
                                {"     "}{feedbackLabels.FeedbackYes}
                            </label>
                        </div>
                        <div className="radio" style={{paddingLeft:"72px"} }>
                            <label>
                                <input type="radio"
                                    value="No"
                                    checked={formValues.IsSatisfied === false}
                                    onChange={handleRadioChange}
                                />
                                {"     "}{feedbackLabels.FeedbackNo}
                            </label>
                        </div>
                    </div>
                </form>
                <div className="feedback-label" >{feedbackLabels.WhatYouLike}</div>
                <div className="help-row" >
                    <textarea className="help-input" style={{ height: '6rem' }} type="textarea" field={'MostLiked'} onChange={setValue}
                        value={formValues.MostLiked} maxLength={500} />
                </div>
                <div className="feedback-label" >{feedbackLabels.HowCanWeImprove}</div>
                <div className="help-row" >
                    <textarea className="help-input" style={{ height: '6rem' }} type="textarea" field={'SuggestedImprovements'} onChange={setValue}
                        value={formValues.SuggestedImprovements} maxLength={500} />
                </div>

                <div className="button-wrapper help-button-row" style={buttonRowStyle} >
                    {sendingEmail ?
                        <button className="button reprice-cart-button" style={{ width: '10rem' }} disabled={true} >{feedbackLabels.FeedbackSending}</button>
                        :
                        <button className="button reprice-cart-button" style={{ width: '10rem' }} onClick={submit}>{feedbackLabels.FeedbackSubmit}</button>
                    }
                </div>
            </div>
        </div>
    )
}
Feedback.propTypes = {
    selectedCustomer: PropTypes.object
};
function mapStateToProps(state) {
    return {
        selectedCustomer: state.user.selectedCustomer,
    };
}
export default connect(mapStateToProps, null)(withStorefrontConfig(withSelectedCustomer(withFeedbackConfig(Feedback))));

const buttonRowStyle = { marginTop: '2rem', marginBottom: '1rem', paddingLeft: '2rem', paddingRight: '2rem' };
