import Api from './Api';
import Notifications from "./Notifications";
import DateHelper from '../helpers/DateHelper';
import * as CartStatusNotifications from "../resources/CartStatusNotification";
import APIMSettingsService from '../services/APIMSettingsService';

class CartService {
    items = [];
    callbacks = [];
    shippingMethod = "Standard_Shipping";
    requestDate = new Date();
    poNumber = "";
    fetchingPricesAndAvailability = false;

    load(userId) {
        return Api.fetch(`/api/cart/${userId}`);
    }

    addItem(item) {
        this.items.push(item);
    }

    count() {
        return this.items.length
    }

    clear(userId) {
        return Api.fetch(`/api/cart/delete/${userId}`, { method: 'DELETE' });
    }

    updateSubscribers() {
        this.callbacks.forEach(cb => cb());
    }

    subscribe(callback) {
        this.callbacks.push(callback);
    }

    addToCartCallback = (status, localized) => {
        if (status === CartStatusNotifications.ConfiguredItemsNotAvailable) {
            Notifications.error(localized.ConfiguredItemsNotAvailable);
        }
        else if (status === CartStatusNotifications.ObsoleteItemsNotAvailable) {
            Notifications.error(localized.ObsoleteItemsNotAvailable);
        }
        else if (status === CartStatusNotifications.OrderUpdated) {
            Notifications.message(localized.OrderUpdated);
        }
        else if (status === CartStatusNotifications.UnableToAddToCart) {
            Notifications.error(localized.UnableToAddToCart);
        }
    }

    populateItems = (res) => {
        (res) ?
            res.forEach(item => this.addItem(item)) :
            console.log("Shopping Cart failed to load.");
    }

    handleMultiplePriceAndAvailabilityResponse = (res, cartActions) => {
        if (res && res.items) {
            cartActions.UpdateAllCartItems(res.items);
        }
        this.updateSubscribers();
    }

    handlePriceAndAvailabilityResponse = res => {
        if (res && res.items) {
            this.items.map(x => {
                const item = res.items.find(({ id }) => id === x.id);
                if (item)
                    x = item;
                return x;
            });
        }
        this.updateSubscribers();
    }

    populateShippingMethod(shippingMethod) {
        (shippingMethod) ?
            this.shippingMethod = shippingMethod :
            this.shippingMethod = "Standard_Shipping";
    }

    populatePONumber(number) {
        this.poNumber = number;
    }

    populateRequestDate(date) {
        (date) ?
            this.requestDate = date :
            this.requestDate = DateHelper.addBusinessDays(Date.now(), 4);
    }

    itemIndex(id) {
        return this.items.findIndex(i => i.id === id, 1);
    }

    subTotal() {
        let sum = 0;

        this.items.forEach(item => {
            sum = sum + (item.product.listPrice.amount * item.quantity);
        })
        return sum;
    }

    netSubTotal() {
        let sum = 0;
        this.items.forEach(item => {
            sum = sum + (item.netPrice * item.quantity);
        })
        return sum;
    }

    add(item) {
        return Api.fetch('/api/cart', { method: 'POST', body: JSON.stringify(item) });
    }

    remove(id) {
        return Api.fetch(`/api/cart/items/${id}`, { method: 'DELETE' });
    }

    updateQuantity(item, quantity) {
        return Api.fetch(`/api/cart/items/${item.id}/quantity`, { method: 'PATCH', body: quantity });
    }

    requestDateUpdated(requestDate) {
        this.requestDate = requestDate;
        this.updateSubscribers();
    }

    getSubmitOrderBodyEComm = (order) => {
        return {
            documentType: order.docType,
            freightHandlingCode: order.freightHandlingCode,
            paymentInstrumentCode: order.paymentInstrument,
            paymentTermsCode: order.paymentTermId,
            requestedDate: DateHelper.getStringDate(order.requestDate),
            priorityCode: order.shippingMethod,
            comments: order.comments,
            customerPO: order.poNumber,
            carrierId: order.storefrontCarrierId,
            deliveryInstruction1: order.deliveryInstructions,
            deliveryInstruction2: order.storefrontCarrier2Id === '' ? order.deliveryInstructions2 : order.storefrontCarrier2Id,
            shipToId: Math.floor(order.selectedCustomer.id),
            soldToId: order.selectedCustomer.billToExternalId && Math.floor(order.selectedCustomer.billToExternalId),
            documentCompany: order.selectedCustomer.companyCode,
            orderedBy: order.orderedBy,
            shipToAddress: {
                mailingName: order.dropshipAddress?.fullName,
                addressLine1: order.dropshipAddress?.addressLine1,
                addressLine2: order.dropshipAddress?.addressLine2,
                city: order.dropshipAddress?.city,
                stateCode: order.dropshipAddress?.stateProvenceRegion,
                postalCode: order.dropshipAddress?.zipCode,
                countryCode: order.dropshipAddress?.country
            },
            items: order.items.map(this.toSubmitEComm()),
            implementationId: order.implementationId,
            useCustomerInventoryPreference: order.useCustomerInventoryPreference,
            useBranchStockingType: order.useBranchStockingType,
            storefrontNumber: order.storefrontNumber,
            selectedBranch: order.selectedBranch
        };
    }

    getSubmitOrderBody = (order) => {
        return {
            comments: order.comments,
            deliveryInstructions: order.deliveryInstructions,
            deliveryInstructions2: order.deliveryInstructions2,
            docType: order.docType,
            dropShipAddress: order.dropshipAddress,
            estimatedDeliveryDate: order.estimatedDeliveryDate,
            freightHandlingCode: order.freightHandlingCode,
            items: order.items.map(this.toSubmit()),
            paymentInstrument: order.paymentInstrument,
            paymentTermId: order.paymentTermId,
            poNumber: order.poNumber,
            requestDate: DateHelper.getStringDate(order.requestDate),
            shippingMethod: order.shippingMethod,
            storefrontCarrierId: order.storefrontCarrierId,
            storefrontCarrier2Id: order.storefrontCarrier2Id,
            selectedBranch: order.selectedBranch
        };
    }

    getOrderRequest = (submitOrder, customer, user, isQuote) => {
        const userAction = isQuote ? 'Placed_Quote' : 'Placed_Order'
        return {
            userAction: userAction,
            submitOrder: {
                ...submitOrder,
                branchPlant: customer.companyCode,
                shipToNumber: customer.id,
            },
            customer: {
                id: customer.id,
                billToId: customer.billToExternalId,
                name: customer.name,
                address: customer.address,
                branchPlant: customer.companyCode
            },
            user: {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                storefront: user.storefrontNumber,
            }
        };
    }

    async submitOrder(orderRequest) {
        let apimUrl = await APIMSettingsService.getAPIManagementUrl();
        const url = `${apimUrl}/place-order/${orderRequest.customer.id}`;
        const orderRequestBody = JSON.stringify(orderRequest);
        return Api.fetch(url, {
            method: 'POST', body: orderRequestBody
        });
    }

    async submitOrderEComm(order) {        
        return Api.fetch(`/api/orders/placeOrder`, { method: 'POST', body: JSON.stringify(order) }, false).then(this.handleOrderResponse);
    }

    async itemBranchVerification(branchItems) {
        return Api.fetch(`/api/products/ItemBranchVerification`, { method: 'POST', body: JSON.stringify(branchItems)}, false).then(this.handleOrderResponse);
    }

    fetchPriceCartEComm(order) {
        return Api.fetch(`/api/products/GetPricedCart`, { method: 'POST', body: JSON.stringify(order) }, false).then(this.handleOrderResponse);
    }

    handleOrderResponse(res) {
        if (res)
            return res.json();
    }

    toSubmit() {
        return i => {
            return {
                sku: i.product.sku,
                quantity: i.quantity,
                pC1DiscountCodeId: i.pc1DiscountCodeId,
                pC2DiscountCodeId: i.pc2DiscountCodeId,
                pC3DiscountCodeId: i.pc3DiscountCodeId,
                requestedDeliveryDate: DateHelper.getStringDate(i.requestedDeliveryDate),
                branch: null
            };
        }
    }

    toSubmitEComm() {
        return i => {
            return {
                productSKU: i.product.sku,
                quantity: i.quantity,
                priceCode1: i.pc1DiscountCodeId,
                priceCode2: i.pc2DiscountCodeId,
                priceCode3: i.pc3DiscountCodeId,
                requestedDate: DateHelper.getStringDate(i.requestedDeliveryDate)
            };
        }
    }

    resetItemsAvailability() {
        for (let i = 0; i < this.items.length; i++) {
            this.items[i].availability = null;
        }
    }
}

export default new CartService();