import React, { useState, useEffect } from 'react';
import Api from '../../services/Api';
import { useSelector, connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import InvoiceFilter from './InvoiceFilter';
import Invoices from './Invoices';
import Paging from '../Paging';
import { useCustomers } from '../../hooks/CustomerContext';
import { useHistory } from 'react-router-dom';
import { withSalesHistoryConfig } from '../../hooks/SalesHistoryConfigContext';
import { withSelectedCustomer } from '../../hooks/withSelectedCustomer';
import { withStorefrontConfig } from '../../hooks/StorefrontSettingsContext';
import { withInvoiceConfig } from '../../hooks/InvoiceConfigContext';
import NotificationBanner from "./../banner/NotificationBanner";

function InvoiceHistory(props) {
    const { currentCustomer: selectedCustomer } = useCustomers();
    const [numberFilter, setNumberFilter] = useState('');
    const [page, setPage] = useState(1);
    const [dateRange, setDateRange] = useState(props.location.query ? props.location.query.days : 30);
    const [sortFilter, setSortFilter] = useState('invoiceDate');
    const [invoices, setInvoices] = useState(null);
    const [invoicesCount, setInvoicesCount] = useState(0);
    const [fetching, setFetching] = useState(false);
    const pageSize = 100;
    const currentUser = useSelector(state => state.user.currentUser);
    const history = props.history || useHistory();

    useEffect(() => {
        if (!selectedCustomer) {
            history.push({ pathname: '/', state: {} });
        } else {
            fetchInvoices();
        }
    }, [selectedCustomer, dateRange, sortFilter, page]);

    const fetchInvoices = () => {
        if (selectedCustomer && selectedCustomer.id) {
            setFetching(true);
            Api.fetch(uri()).then(load);
        }
    };

    const load = (data) => {
        setInvoices(data.invoices);
        setInvoicesCount(data.totalCount);
        setFetching(false);
    }

    const nextPage = () => {
        if (page < pageCount() && !fetching) {
            setPage(page + 1);
        }
    }

    const previousPage = () => {
        if (page > 1 && !fetching) {
            setPage(page - 1);
        }
    }

    const pageCount = () => {
        return Math.ceil(invoicesCount / pageSize);
    }

    const setNumberQuery = (e) => {
        setPage(1);
        setNumberFilter(e.target.value);
    }

    const setSortQuery = (e) => {
        setPage(1);
        setSortFilter(e.target.value);
    }

    const setDateRangeQuery = (e) => {
        setPage(1);
        setDateRange(e.target.value);
    }

    const handleEnter = (e) => {
        if (e.key === "Enter")
            fetchInvoices();
    }

    const uri = () => {
        let clauses = [];
        if (currentUser)
            clauses.push(`userStorefront=${currentUser.storefrontNumber}`);

        if (numberFilter)
            clauses.push(`searchValue=${numberFilter}`);

        if (selectedCustomer)
            clauses.push(`selectedCustomer=${selectedCustomer.id}`);

        if (sortFilter)
            clauses.push(`sort=${sortFilter}`);

        if (pageSize)
            clauses.push(`pageSize=${pageSize}`);

        if (page)
            clauses.push(`page=${page}`);

        if (dateRange)
            clauses.push(`days=${dateRange}`);

        return [`/api/invoices/GetInvoices`, clauses.filter(c => !!c).join("&")].join("?");
    };

    return (
        <div>
            <NotificationBanner
                classOverride="mb-4"
                page="Invoices"
                locale={props.StorefrontConfig.locale}
            />

            <InvoiceFilter
                setNumberQuery={setNumberQuery}
                handleEnter={handleEnter}
                search={fetchInvoices}
                setDateRangeQuery={setDateRangeQuery}
                setSortQuery={setSortQuery}
                dateRange={dateRange}
                sortFilter={sortFilter}
                numberFilter={numberFilter}
            />

            <Invoices invoices={invoices} fetching={fetching} />

            <Paging
                activePage={page}
                pageCount={pageCount}
                nextPage={nextPage}
                previousPage={previousPage}
            />
        </div>
    );

}

function mapStateToProps(state) {
    return {
        selectedCustomer: state.user.selectedCustomer,
        currentUser: state.user.currentUser

    };
}

export default connect(
    mapStateToProps, null

)(withRouter(withInvoiceConfig(withStorefrontConfig(withSelectedCustomer(InvoiceHistory)))));