import React, { useState } from 'react'
import { CSVLink } from "../../modules/react-csv";
import { useCustomers } from '../../hooks/CustomerContext';
import { useSalesHistoryConfig } from '../../hooks/SalesHistoryConfigContext';
import { useStorefrontSettings } from '../../hooks/StorefrontSettingsContext';
import SalesHistoryReportFilters from './SalesHistoryReportFilters';
import CustomerService from '../../services/Customer';
import Loading from '../Loading';
import DateDisplay from '../DateDisplay';
import DateHelper from '../../helpers/DateHelper';
import Configuration from '../../resources/Configuration';
import NotificationBanner from "../banner/NotificationBanner";

function CustomerDetailsByTerritory(props) {
    const { currentCustomer: selectedCustomer } = useCustomers();
    const salesHistoryConfig = useSalesHistoryConfig();
    const storefrontSettings = useStorefrontSettings();
    const localized = salesHistoryConfig.labels;
    const [fetching, setFetching] = useState(false);
    const [customerData, setCustomerData] = useState([]);

    const { storefront } = props.user;
    const { italyStorefront } = Configuration.storefronts;
    const isItalyStorefront = storefront.number.includes(italyStorefront);

    const getCSVData = () => {
        if (!customerData || customerData.length === 0) {
            return [];
        }

        const content = [];

        customerData.forEach((item) => {
            const {
                addressNumber,
                alphaName,
                taxId,
                addressLine1,
                addressLine2,
                addressLine3,
                addressLine4,
                postalCode,
                city,
                state,
                prefix,
                phoneNumber,
                electronicAddress,
                industryClassification,
                dateAccountOpened,
                parentNumber,
                parentNumberName,
                adjustmentSchedule,
                categoryCode12,
                categoryCode04,
                categoryCode16,
                categoryCode17,
                categoryCode18,
                categoryCode19,
                categoryCode20,
                categoryCode25
            } = item;

            const data = {
                addressNumber: addressNumber ?? null,
                alphaName: alphaName ?? null,
                taxId: '=""' + (taxId ?? '') + '""' ?? null,
                addressLine1: addressLine1 ?? "",
                addressLine2: addressLine2 ?? "",
                addressLine3: addressLine3 ?? "",
                addressLine4: addressLine4 ?? "",
                postalCode: postalCode ?? null,
                city: city ?? "",
                state: state ?? "",
                prefix: prefix ?? "",
                phoneNumber: phoneNumber ?? null,
                electronicAddress: electronicAddress ?? "",
                industryClassification: industryClassification ?? "",
                dateAccountOpened: DateHelper.format(dateAccountOpened, "dd/MM/yyyy") ?? "",
            };

            if (isItalyStorefront) {
                data.categoryCode12 = categoryCode12 ?? null;
            } else {
                data.parentNumber = parentNumber ?? null,
                data.parentNumberName = parentNumberName ?? "",
                data.adjustmentSchedule = adjustmentSchedule ?? null,
                data.categoryCode04 = categoryCode04 ?? null;
                data.categoryCode16 = categoryCode16 ?? null;
                data.categoryCode17 = categoryCode17 ?? null;
                data.categoryCode18 = categoryCode18 ?? null;
                data.categoryCode19 = categoryCode19 ?? null;
                data.categoryCode20 = categoryCode20 ?? null;
                data.categoryCode25 = categoryCode25 ?? null;
        }

            content.push(data);
        });

        return content;
    }

    const CSVHeaders = [
        { label: localized.AddressNumber, key: "addressNumber" },
        { label: localized.AlphaName, key: "alphaName" },
        { label: localized.TaxID, key: "taxId" },
        { label: localized.AddressLine1, key: "addressLine1" },
        { label: localized.AddressLine2, key: "addressLine2" },
        { label: localized.AddressLine3, key: "addressLine3" },
        { label: localized.AddressLine4, key: "addressLine4" },
        { label: localized.PostalCode, key: "postalCode" },
        { label: localized.City, key: "city" },
        { label: localized.State, key: "state" },
        { label: localized.Prefix, key: "prefix" },
        { label: localized.PhoneNumber, key: "phoneNumber" },
        { label: localized.ElectronicAddress, key: "electronicAddress" },
        { label: localized.IndustryClassification, key: "industryClassification" },
        { label: localized.DateAccountOpened, key: "dateAccountOpened" },
    ];

    const categoryCodeMap = {
        Italy: [{ label: localized.CategoryCode12, key: 'categoryCode12' }],
        default: [
            { label: localized.ParentNumber, key: "parentNumber" },
            { label: localized.ParentNumberName, key: "parentNumberName" },
            { label: localized.AdjustmentSchedule, key: "adjustmentSchedule" },
            { label: localized.CategoryCode04, key: 'categoryCode04' },
            { label: localized.CategoryCode16, key: 'categoryCode16' },
            { label: localized.CategoryCode17, key: 'categoryCode17' },
            { label: localized.CategoryCode18, key: 'categoryCode18' },
            { label: localized.CategoryCode19, key: 'categoryCode19' },
            { label: localized.CategoryCode20, key: 'categoryCode20' },
            { label: localized.CategoryCode25, key: 'categoryCode25' },
        ],
    };

    const classNameMap = {
        Italy: 'it',
        default: 'br',
    };

    function getClassNamePrefix(displayName) {
        return classNameMap[displayName] || classNameMap.default;
    }

    const classNamePrefix = getClassNamePrefix(storefront.displayName);

    function getCategoryCode(displayName) {
        return categoryCodeMap[displayName] || categoryCodeMap.default;
    }

    if (props.user && props.user.storefront && localized) {
        const { storefront } = props.user;
        const categoryCode = getCategoryCode(storefront.displayName);
        CSVHeaders.push(...categoryCode);
    }

    const fetchSalesByCategory = (territoryCode, companyCode) => {
        if (selectedCustomer) {
            setCustomerData([]);
            setFetching(true);
            CustomerService.getCustomersByTerritory(territoryCode, companyCode).then((response) => {
                if (response) {
                    if (response?.length > 0) {
                        setCustomerData(response);
                    }
                }
                setFetching(false);
            });
        }
    };

    const renderSalesHistoryReportFilters = () => {
        return (
            <SalesHistoryReportFilters fetchSalesReport= {fetchSalesByCategory} isCustomerDetailsReport={true}/>);
    };

    const renderSalesHistoryHeaders = () => {
        return (
            <>
                <div className="display-2 d-flex justify-content-start" style={{ marginBottom: '50px' }}>
                    <b className='title'>{localized.CustomerListByTerritoryReport}</b>
                </div>
                <div className="seperator"></div>
            </>
        );
    };

    const renderSalesHistoryGrid = () =>{
        return (
            <>
            {renderExportData()}
            <div className='table-scrolling-wrapper'>
                {renderSalesHistoryGridHeader()}
                {
                    fetching &&
                    <Loading type="brand" />
                }
                {
                    !fetching && (customerData && customerData.length === 0) &&
                    <div data-cy="report-details-no-results" className="details no-result">
                        {localized.NoCustomerDetailsFound}
                    </div>
                }
                {!fetching && (customerData && customerData.length > 0) && renderSalesHistoryData()}
            </div>
            </>
        );
    };

    const renderExportData = () => {
        return (
            <>
            {
                !fetching && (customerData && customerData.length > 0) &&
                <div className="export-button button-wrapper">
                            <button>
                                <CSVLink id="orders-csv-link" separator={localized.ExcelSeparator} filename={"Customer Report.xls"} data={getCSVData()}
                            headers={CSVHeaders} className="button button-alt">
                            {localized.Export}
                        </CSVLink>
                    </button>
                </div>
            }
            </>
        );
    };

    const renderSalesHistoryGridHeader = () => {
        return (
            <div className={`${classNamePrefix}-customer-details-header grid`}>
                <div className="label">{localized.AddressNumber}</div>
                <div className="label">{localized.AlphaName}</div>
                <div className="label">{localized.TaxID}</div>
                <div className="label">{localized.AddressLine1}</div>
                <div className="label">{localized.AddressLine2}</div>
                <div className="label">{localized.AddressLine3}</div>
                <div className="label">{localized.AddressLine4}</div>
                <div className="label">{localized.PostalCode}</div>
                <div className="label">{localized.City}</div>
                <div className="label">{localized.State}</div>
                <div className="label">{localized.Prefix}</div>
                <div className="label">{localized.PhoneNumber}</div>
                <div className="label">{localized.ElectronicAddress}</div>
                <div className="label">{localized.IndustryClassification}</div>
                <div className="label">{localized.DateAccountOpened}</div>

                {isItalyStorefront ? (
                    <div className="label">{localized.CategoryCode12}</div>
                ) : (
                        <>
                        <div className="label">{localized.ParentNumber}</div>
                        <div className="label">{localized.ParentNumberName}</div>
                        <div className="label">{localized.AdjustmentSchedule}</div>
                        <div className="label">{localized.CategoryCode04}</div>
                        <div className="label">{localized.CategoryCode16}</div>
                        <div className="label">{localized.CategoryCode17}</div>
                        <div className="label">{localized.CategoryCode18}</div>
                        <div className="label">{localized.CategoryCode19}</div>
                        <div className="label">{localized.CategoryCode20}</div>
                        <div className="label">{localized.CategoryCode25}</div>
                    </>
                )}
            </div>
        );
    };

    const renderSalesHistoryData = () => {
        return (
            <div data-cy="report-history" className="br-customer">
                {customerData.map((item, key) => renderCustomerRowData(item,key))}
            </div>
        );
    };

    const renderCustomerRowData = (customer, index) => {
        return (
            <div className={`${classNamePrefix}-customer-details grid`} key={index}>
                <div className="fele-content">{customer.addressNumber}</div>
                <div className="fele-content">{customer.alphaName}</div>
                <div className="fele-content">{customer.taxId}</div>
                <div className="fele-content">{customer.addressLine1}</div>
                <div className="fele-content">{customer.addressLine2}</div>
                <div className="fele-content">{customer.addressLine3}</div>
                <div className="fele-content">{customer.addressLine4}</div>
                <div className="fele-content">{customer.postalCode}</div>
                <div className="fele-content">{customer.city}</div>
                <div className="fele-content">{customer.state}</div>
                <div className="fele-content">{customer.prefix}</div>
                <div className="fele-content">{customer.phoneNumber}</div>
                <div className="fele-content">{customer.electronicAddress}</div>
                <div className="fele-content">{customer.industryClassification}</div>
                <div className="fele-content">
                    <DateDisplay date={customer.dateAccountOpened} dateFormat="dd/MM/yyyy" />
                </div>


                {isItalyStorefront ? (
                    <div className="fele-content">{customer.categoryCode12}</div>
                ) : (
                        <>
                        <div className="fele-content">{customer.parentNumber}</div>
                        <div className="fele-content">{customer.parentNumberName}</div>
                        <div className="fele-content">{customer.adjustmentSchedule}</div>
                        <div className="fele-content">{customer.categoryCode04}</div>
                        <div className="fele-content">{customer.categoryCode16}</div>
                        <div className="fele-content">{customer.categoryCode17}</div>
                        <div className="fele-content">{customer.categoryCode18}</div>
                        <div className="fele-content">{customer.categoryCode19}</div>
                        <div className="fele-content">{customer.categoryCode20}</div>
                        <div className="fele-content">{customer.categoryCode25}</div>
                    </>
                )}
            </div>
        )
    };

    return (
    <div data-cy="report-order-history" className="fele-order-history">
        <NotificationBanner
            classOverride="mb-4"
            page="CustomerListByTerritory"
            locale={storefrontSettings.locale}
        />

        {renderSalesHistoryHeaders()}
        {renderSalesHistoryReportFilters()}
        {renderSalesHistoryGrid()}
    </div>
    )
}

export default CustomerDetailsByTerritory;