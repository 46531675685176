import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Calendar from '../form-controls/Calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import BasicModal from '../shared/ModalBasic';
import { useCartConfig } from '../../hooks/CartConfigContext';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DateHelper from '../../helpers/DateHelper';

const HeaderRequestDate = (props) => {
    const cartOptions = useCartConfig();   
    const localized = cartOptions.labels;
    const minDate = DateHelper.subtract(new Date(), { days: (cartOptions.transitDays * -1 )});

    const [showRequestDateMessage, setShowRequestDateMessage] = useState(false);
    const buttonStyle = {
        border: 'none',
        backgroundColor: 'rgba(0,0,0,0)',
    };

    if (cartOptions.childComponents.HeaderRequestDate.shown !== true) {
        return null;
    }

    return (
        <div id="RequestDateSelector" className="fele-request-date">
            <div className="flex">
                <div className="label">{localized.RequestedDeliveryDateLabel}</div>
                {cartOptions.childComponents.HeaderRequestDateLabel.shown === true && (
                    <div className="flex-1 ml-4">
                        <button
                            style={buttonStyle}
                            onClick={() => setShowRequestDateMessage(!showRequestDateMessage)}
                        >
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </button>
                    </div>
                )}
            </div>
            <div className="flex items-center">
                <Calendar
                    date={props.requestDate}
                    minDate={minDate}
                    callBackFunction={props.updateDate}
                    showIcon={true}
                    dateFormat={props.dateFormat}
                    className="mr-2"
                /> 
                <Tooltip
                    title={<h3>{localized.RequestedDeliveryDateTooltip}</h3>}
                >
                    <InfoOutlinedIcon fontSize="large" style={{ marginLeft: '8px',marginTop:'8px' }} />
                </Tooltip>
            </div>
            <BasicModal
                isOpen={showRequestDateMessage}
                onClose={() => setShowRequestDateMessage(false)}
            >
                <div>
                    La fecha requerida es cálculada en base a los días de preparación y los días de tránsito del material según su ubicación
                </div>
            </BasicModal>
        </div>
    );
};

HeaderRequestDate.propTypes = {
    updateDate: PropTypes.func,
    requestDate: PropTypes.object,
    components: PropTypes.object,
    dateFormat: PropTypes.string, 
};

export default HeaderRequestDate;