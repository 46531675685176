import React from 'react';
import Api from '../../services/Api';
import Availability from './Availability';
import Loading from '../Loading';
import { withProductConfig } from '../../hooks/ProductConfigContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { withStorefrontConfig } from '../../hooks/StorefrontSettingsContext';

export class SummaryAvailability extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            avail: null,
            fetchingPrice: true,
            stockingType: props.stockingType
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.getSummaryAvailability();
    }

    setMountedState = (state, callback) => {
        if (this.mounted)
            this.setState(state, () => callback && callback());
    }

    getSummaryAvailability = () => {
        this.setMountedState({ fetchingPrice: true });

        const availabilityRequest = this.getAvailabilityRequest();

        return Api.fetch(`/api/products/GetDetailedAvailability`, { method: 'POST', body: JSON.stringify(availabilityRequest) }, false).then(this.handleResponse).then(this.loadSummaryAvailability);
    }

    handleResponse(res) {     

        try {
            if (res)
                return res.json();
        } catch (error) {
                      
        }
    }

    getAvailabilityRequest = () => {
        return {
            customerId: this.props.customer.id,
            storefront: this.props.userStorefront,
            company: this.props.customer.companyCode,
            sku: this.props.sku,
            transactionQuantity: this.props.quantity?.toString() || "1",
            implementationId: this.props.StorefrontConfig.storefront.implementationId,
            useBranchStockingType: this.props.productConfig.settings.UseBranchStockingType,
            countryCode: this.props.customer.address?.country,
            OverrideBUDescription: this.props.productConfig.settings.OverrideBUDescription
        };
    }

    loadSummaryAvailability = (resp) => {
        this.setMountedState({ fetchingPrice: false });
        if (resp) {
            this.setMountedState({ avail: resp.availabilitySummaries });
        }
    }

    getAvailability = (backordered, quantity, branchStockingType) => {
        if (this.props.showAvailable === true && branchStockingType === "M") {
            return "Available";
        }

        if (backordered === true && this.state.stockingType !== "U")
            return "Backordered";

        if (quantity > 0) {
            if (quantity < this.props.quantity) {
                return "Partially Available";
            } else {
                return "Available";
            }
        } else {
            return "Unavailable";
        }
    }

    render() {
        const { avail } = this.state;
        const localized = this.props.productConfig.labels;
        return (
            <React.Fragment>
                {
                    this.state.fetchingPrice &&
                    <Loading />
                }
                {
                    avail &&
                    <div>
                        <div className="label">{localized.AvailabilityByLocation_Label}</div>
                        <table id="avail_by_location">
                            <tbody>
                                {avail.map((s, key) =>
                                    <tr key={key}>
                                        <td>
                                            <Availability availability={this.getAvailability(s.backordered, s.quantity, s.branchStockingType)} asIcon={false} />
                                        </td>
                                        <td>
                                            {
                                                this.props.productConfig.settings.ShowUseUpQuantity === true
                                                && this.state.stockingType === "U"
                                                && s.quantity > 0 &&
                                                <div style={{ whiteSpace: 'nowrap', marginTop: '4px' }}>
                                                    <FontAwesomeIcon icon={faExclamationTriangle} color="orange" /> {localized.QuantityAvailableLabel.replace("{0}", s.quantity)}
                                                </div>
                                            }
                                        </td>
                                        <td style={{ paddingLeft: "1.5rem" }}>
                                            <span> {s.description} </span>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                }
            </React.Fragment>

        );
    }
}

export default withStorefrontConfig(withProductConfig(SummaryAvailability))