import React from 'react';
import PricingHelper from '../../helpers/PricingHelper';
import Strings from '../cart/Cart.Strings';
import * as Roles from '../../resources/UserRole';
import Notifications from '../../services/Notifications';
import Price from './Price';
import { Link } from 'react-router-dom';
import Loading from '../Loading';
import Availability from './Availability';
import MessageWarning from '../MessageWarning';
import Authorize from '../Authorize';
import Api from '../../services/Api';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { withProductConfig } from '../../hooks/ProductConfigContext';
import { withStorefrontConfig } from '../../hooks/StorefrontSettingsContext';

export class ProductSearchResultItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            price: null,
            availability: null,
            fetchingPrice: false,
            quantity: 1,
            updateButtonIsVisible: false,
            redraw: props.redraw,
            branchStockingType: null
        }
    }

    handleAddToCart = () => {
        const localized = this.props.productConfig.labels;
        if (this.state.fetchingPrice === false)
            return this.addToCart(localized);
        Notifications.error(localized.FetchingPriceMessage);
    }

    addToCart = (localized) => {
        let product = { product: this.props.product, quantity: this.state.quantity, netPrice: this.state.price };
        if (this.state.availability === "Unavailable" || this.state.availability === "Unknown")
            return Notifications.error(localized.UnavailableMessage);

        this.props.AddItemToCart(product, localized);
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleQtyBlur = (e) => {
        if (this.nodeQuantity && this.nodeQuantity.contains(e.target))
            return;
        if (this.state.quantity === "")
            this.setState({ quantity: 1 });
        this.setState({ quantity: this.state.quantity, updateButtonIsVisible: false });
    }

    handleQtyOnFocus = (e) => {
        this.setState({ updateButtonIsVisible: true });
    }

    handleQtyChange = (e) => {
        this.setState({ updateButtonIsVisible: true });
        if (e.target.value === "") {
            this.setState({ quantity: e.target.value });
        }
        else if (isPositiveNumber(e.target.value)) {
            this.setState({ quantity: parseInt(e.target.value) });
        }
    }

    handleQtyKeyUp = (e) => {
        if (e.keyCode === 13)
            this.handleQtyUpdateClick();
    }

    handleQtyUpdateClick = () => {
        this.setState({ updateButtonIsVisible: false });
        this.fetchPricing();
    }

    loadPricing = (data) => {
        if (data) {
            this.setState({
                price: data && this.props.currentUser.roles.includes(Roles.VIEW_NET_PRICE) ? { amount: data.price, currency: data.currency } : this.props.product.listPrice,
                fetchingPrice: false,
                availability: data.availability,
                branchStockingType: data.branchStockingType
            });
        }
    }

    fetchPricing = () => {
        if (this.props.selectedCustomer && this.props.selectedCustomer.billingType !== "1" && !PricingHelper.isItemConfigured(this.props.product) && !PricingHelper.isItemObsolete(this.props.product)) {
            this.setState({ fetchingPrice: true }, () => {
                const priceAvailRequest = this.getPriceAvailabilityRequest();
                Api.fetch(`/api/products/GetPriceAndAvailability`, { method: 'POST', body: JSON.stringify(priceAvailRequest) }, false).then(this.handleResponse).then(this.loadPricing)
            });
        }
        else {
            this.setState({ fetchingPrice: false });
        }
    }

    handleResponse(res) {
        if (res)
            return res.json();
    }

    getPriceAvailabilityRequest = () => {
        return {
            customerId: this.props.selectedCustomer.externalId,
            storefront: this.props.currentUser.storefrontNumber,
            company: this.props.selectedCustomer.companyCode,
            sku: this.props.product.sku,
            transactionQuantity: this.state.quantity?.toString() || "1",
            implementationId: this.props.StorefrontConfig.storefront.implementationId
        };
    }

    render() {
        const { product, selectedCustomer, hideListPrice, checkPriceAvailability } = this.props;
        const isConfiguredProduct = PricingHelper.isItemConfigured(product);
        const isObsoleteProduct = PricingHelper.isItemObsolete(product);
        const maxLines = 100;
        const localized = this.props.productConfig.labels;
        return (
            <div className="fele-product-details">
                <div className="grid">
                    {/* {
                        product.name &&
                        <div className="flex-row">
                            <div className="new-name">
                                <div className="content">
                                    {product.name}
                                </div>
                            </div>
                        </div>
                    } */}
                    <div className="flex-row">
                        <div className="product-image">
                            {
                                product.imageUrl &&
                                <div className="content">
                                    <img alt={product.sku} className="img-fluid" src={`${product.imageUrl}100x75px@1x/${product.sku}.jpg?q=80&x.template=y&crop=false`} />
                                </div>
                            }
                        </div>
                        <div className="part-name">
                            <div className="label">{localized.Description_Label}</div>
                            <div className="content">
                                {product.description}</div>
                        </div>
                        <div className="sku">
                            <div className="label">{localized.Item_Label}</div>
                            <div className="content"><Link to={{ pathname: `/product/${product.sku}`, state: { selectedCustomer: selectedCustomer } }}>{product.sku}</Link></div>
                        </div>
                        {
                            product.listPrice && !hideListPrice &&
                            <div className="list-price">
                                <div className="label">{localized.ListPriceLabel}</div>
                                <div className="content"> <Price price={product.listPrice} country={selectedCustomer.address.country} /></div>
                            </div>
                        }
                        <div className="cost">
                            {
                                checkPriceAvailability &&
                                !isConfiguredProduct &&
                                !isObsoleteProduct &&
                                <div>
                                    {
                                        !this.state.fetchingPrice && !this.state.price && !this.state.availability &&
                                        <div className="button-wrapper">
                                            <button className="button button-alt" onClick={this.handleQtyUpdateClick}>{localized.AvailabilityCost_ButtonText}</button>
                                        </div>
                                    }
                                    {this.state.fetchingPrice && <Loading />}

                                    {
                                        !this.state.fetchingPrice && this.state.price && this.state.availability &&
                                        <React.Fragment>
                                            <div className="label">{localized.YourCostLabel}</div>
                                            <Availability availability={this.state.availability} asIcon={false} />
                                            <Price price={this.state.price} country={selectedCustomer.address.country} />
                                        </React.Fragment>
                                    }
                                </div>
                            }
                        </div>
                        {
                            checkPriceAvailability &&
                            product.landedCostRule !== "NEW" &&
                            !isConfiguredProduct &&
                            !isObsoleteProduct &&
                            <div className="availability">
                                <div className="label">{localized.AvailabilityLabel}:</div>
                                {
                                    this.state.fetchingPrice &&
                                    <div className="pill">
                                        <Loading />
                                    </div>
                                }
                                {
                                    !this.state.fetchingPrice &&
                                    <Availability availability={this.state.availability} />
                                }
                            </div>
                        }
                        {
                            product && (isConfiguredProduct || isObsoleteProduct) &&
                            <div className="availability">
                                {
                                    isConfiguredProduct &&
                                    <MessageWarning title={"Configured Item"} message={"Configured items are not currently available for purchase in FE-Direct, Please contact your account manager for price and availability"} />
                                }
                                {
                                    isObsoleteProduct &&
                                    <MessageWarning title={localized.ObsoleteItem} message={localized.OboleteItemsNotAvailable} />
                                }
                            </div>
                        }
                        <div className="add-to-cart">
                            {
                                checkPriceAvailability &&
                                product.landedCostRule !== "NEW" &&
                                !isConfiguredProduct &&
                                !isObsoleteProduct &&
                                <Authorize hasRoles={[Roles.DEVELOPER, Roles.PLACE_ORDERS, Roles.PLACE_QUOTES]} displayMessage={false}>

                                    <div className="flex">
                                        <div className="input-wrapper quantity" ref={node => (this.nodeQuantity = node)}>
                                            <input
                                                type="text"
                                                name="quantity"
                                                value={this.state.quantity}
                                                onChange={this.handleQtyChange}
                                                onBlur={this.handleQtyBlur}
                                                onFocus={this.handleQtyOnFocus}
                                                onKeyUp={this.handleQtyKeyUp}
                                                autoComplete="off" />
                                            {
                                                this.state.updateButtonIsVisible &&
                                                <div className="update button-wrapper small">
                                                    <button className="button button-alt" onClick={this.handleQtyUpdateClick} >{localized.ButtonText_CheckAvailability}</button>
                                                </div>
                                            }
                                        </div>
                                        <Authorize hasRoles={[Roles.PLACE_ORDERS]} displayMessage={false}>
                                            <div className="button-wrapper add-to-order">
                                                {
                                                    this.props.cartLines.length < maxLines &&
                                                    <button className="button" onClick={this.handleAddToCart}>{localized.ButtonText_AddToOrder}</button>
                                                }
                                                {
                                                    this.props.cartLines.length >= maxLines &&
                                                    <FontAwesomeIcon icon={faExclamationTriangle} color="orange" title={localized.CartMaxLines} />
                                                }
                                            </div>
                                        </Authorize>
                                    </div>
                                </Authorize>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function isPositiveNumber(value) {
    let exp = new RegExp(/^[1-9]\d*$/)
    return exp.test(value);
}

ProductSearchResultItem.propTypes = {
    product: PropTypes.object,
    selectedCustomer: PropTypes.object,
    currentUser: PropTypes.object,
    hideListPrice: PropTypes.bool,
    checkPriceAvailability: PropTypes.bool
}

export default (withStorefrontConfig(withProductConfig(ProductSearchResultItem)))