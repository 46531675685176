import initialState from './initialState';
import * as actions from '../actions/actionTypes';
import PricingHelper from '../helpers/PricingHelper';
import DateHelper from '../helpers/DateHelper';

export default function cartReducer(state = initialState.cart, action) {
    let newState = null;
    switch (action.type) {
        case actions.ADD_ITEM:
            // we could look up if item exists and just update quantity easily here.
            let newItems = state.items;
            newItems.unshift(action.item);
            newState = {
                ...state,
                items: newItems,
                itemCount: PricingHelper.countItems(newItems),
                repriceMessageDisplayed: true,
            };
            break;
        case actions.IMPORT_CART_ITEMS:           
            let newImportCartItems = [...state.items, ...action.items];
            newState = {
                ...state,
                items: newImportCartItems,
                itemCount: PricingHelper.countItems(newImportCartItems),
                
            };
            break;
        case actions.UPDATE_ALL_ITEMS:
            let updatedItems = PricingHelper.mapPricedItems(state.items, action.items, state.requestDate);
            newState = {
                ...state,
                items: updatedItems,
                itemCount: PricingHelper.countItems(updatedItems),
                repriceMessageDisplayed: false,
                netSubtotal: PricingHelper.getNetSubtotal(state.items),
                netCurrency: PricingHelper.getNetCurrency(state.items),
            };
            break;
        case actions.UPDATE_ITEM:
            let updateItems = state.items;
            let foundItem = updateItems.find(i => i.id === action.item.id);
            if (foundItem) {
                if (action.item.quantity)
                    foundItem.quantity = action.item.quantity;
                if (action.item.pC1DiscountCodeId)
                    foundItem.pC1DiscountCodeId = action.item.pC1DiscountCodeId;
                if (action.item.pC2DiscountCodeId)
                    foundItem.pC2DiscountCodeId = action.item.pC2DiscountCodeId;
                if (action.item.pC3DiscountCodeId)
                    foundItem.pC3DiscountCodeId = action.item.pC3DiscountCodeId;
                if (action.item.requestedDeliveryDate)
                    foundItem.requestedDeliveryDate = DateHelper.getUTCDate(action.item.requestedDeliveryDate);
            }

            newState = {
                ...state,
                items: updateItems,
                itemCount: PricingHelper.countItems(updateItems),
                repriceMessageDisplayed: true,
            };
            break;
        case actions.REMOVE_ITEM:
            let filteredItems = state.items.filter(function (i) {
                return i.id !== action.id;
            });
            newState = {
                ...state,
                items: filteredItems,
                itemCount: PricingHelper.countItems(filteredItems),
                fetchingPricesAndAvailability: state.fetchingPricesAndAvailability.filter(function (i) {
                    return i !== action.id;
                }),
                repriceMessageDisplayed: true,
            };
            break;
        case actions.CLEAR_CART:
            let currentShippingMethod = initialState.shippingMethod;
            newState = {
                ...state,
                items: [],
                itemCount: 0,
                netSubtotal: 0,
                netCurrency: "",
                errors: [],
                warnings: [],
                estimatedDeliveryDate: null,
                shippingMethod: currentShippingMethod,
                requestDate: DateHelper.getNewDate(),
                poNumber: "",
                phoneNumber: "",
                deliveryInstructions: "",
                deliveryInstructions2: "",
                accountString: "",
                preferredString:"",
                comments: "",
                dropshipAddress: initialState.cart.dropshipAddress,
                fetchingPricesAndAvailability: [],
                storefrontCarrierId: null,
                paymentTermId: action.item.paymentTerms,
                paymentInstrument: action.item.paymentInstrument,
                storefrontCarrier2Id: null,
                freightHandlingCode: action.item.freightHandlingCode,
                selectedBranch: action.item.selectedBranch
            }
            break;
        case actions.ORDER_LOADED:
            newState = {
                ...state,
                items: action.item.items,
                itemCount: PricingHelper.countItems(action.item.items),
                shippingMethod: action.item.shippingMethod || "",
                poNumber: action.item.poNumber || "",
                phoneNumber: action.item.phoneNumber || "",
                deliveryInstructions: action.item.deliveryInstructions || "",
                deliveryInstructions2: action.item.deliveryInstructions2 || "",
                freightHandlingCode: action.item.freightHandlingCode || "",
                selectedBranch: action.item.selectedBranch || "",
                accountString: action.item.accountString || "",
                preferredString: action.item.preferredString ||"",
                comments: action.item.comments || "",
                dropshipAddress: action.item.dropshipAddress || initialState.cart.dropshipAddress,
                paymentTermId: action.item.paymentTermId || "",
                requestDate: DateHelper.determineRequestDate(action.item.requestDate, state.requestDate),
                netSubtotal: PricingHelper.getNetSubtotal(action.item.items),
                netCurrency: PricingHelper.getNetCurrency(action.item.items),
            };
            break;
        case actions.DROPSHIP_UPDATED:
            newState = {
                ...state,
                dropshipAddress: action.item
            };
            break;
        case actions.FETCH_PRICE:
            let fetchItems = [];
            const fetch = state.fetchingPricesAndAvailability.find(f => f === action.item.id);
            const exists = state.items.find(i => i.id === action.item.id);
            if (action.item.type === "remove") {
                fetchItems = state.fetchingPricesAndAvailability.filter(function (i) {
                    return i !== action.item.id;
                });
            } else {
                fetchItems = state.fetchingPricesAndAvailability;
                if (!fetch && exists) {
                    fetchItems.push(action.item.id);
                }
            }
            if (!fetchItems) {
                fetchItems = [];
            }
            newState = {
                ...state,
                fetchingPricesAndAvailability: fetchItems,
                netSubtotal: PricingHelper.getNetSubtotal(state.items),
                netCurrency: PricingHelper.getNetCurrency(state.items),
            };
            break;
        case actions.CLEAR_FETCHING:
            newState = {
                ...state,
                fetchingPricesAndAvailability: [],
            };
            break;
        case actions.SET_CUSTOMER:
            newState = {
                ...state,
                repriceMessageDisplayed: true,
            };
            break;
        case actions.LOAD_STOREFRONT_SETTINGS:
            let shippingMethod = initialState.shippingMethod;
            if (action.storefrontSettings.shippingOptions) {
                shippingMethod = action.storefrontSettings.shippingOptions[0].method;
            }
            newState = {
                ...state,
                shippingMethod: shippingMethod,
            };
            break;
        case actions.RECEIVED_CUSTOMER_PREFERENCES:
            newState = {
                ...state,
                paymentTermId: action.preferences.paymentTermsAR,
                paymentInstrument: action.preferences.paymentInstrument,
                freightHandlingCode: action.preferences.freightHandlingCode || state.freightHandlingCode,
            };
            break;
        case actions.UPDATE_ORDER_HEADER:
            const payTermId = (action.item.paymentTerms !== null) ? action.item.paymentTerms.paymentTermId : "";
            const payInstrument = (action.item.paymentTerms !== null) ? action.item.paymentTerms.paymentInstrument : "";
            newState = {
                ...state,
                requestDate: DateHelper.determineRequestDate(action.item.requestDate, state.requestDate),
                paymentTermId: payTermId,
                paymentInstrument: payInstrument,
                freightHandlingCode: action.item.freightHandlingCode || state.freightHandlingCode,
                selectedBranch: action.item.selectedBranch || state.selectedBranch,
                repriceMessageDisplayed: true,
            };
            break;
        case actions.UPDATE_ORDER_REVIEW:
            newState = {
                ...state,
                comments: (action.item.comments !== null) ? action.item.comments : state.comments,
                accountString: (action.item.accountString !== null) ? action.item.accountString : state.accountString,
                preferredString: (action.item.preferredString !== null) ? action.item.preferredString : state.preferredString,
                freightHandlingCode: (action.item.freightHandlingCode !== null) ? action.item.freightHandlingCode : state.freightHandlingCode,
                selectedBranch: (action.item.selectedBranch !== null) ? action.item.selectedBranch : state.selectedBranch,
                deliveryInstructions: (action.item.deliveryInstructions !== null) ? action.item.deliveryInstructions : state.deliveryInstructions,
                deliveryInstructions2: (action.item.deliveryInstructions2 !== null) ? action.item.deliveryInstructions2 : state.deliveryInstructions2,
                poNumber: (action.item.poNumber !== null) ? action.item.poNumber : state.poNumber,
                phoneNumber: (action.item.phoneNumber !== null) ? action.item.phoneNumber : state.phoneNumber,
                requestDate: DateHelper.determineRequestDate(action.item.requestDate, state.requestDate),
                shippingMethod: action.item.shippingMethod || state.shippingMethod,
                storefrontCarrierId: action.item.storefrontCarrierId || state.storefrontCarrierId,
                storefrontCarrier2Id: action.item.storefrontCarrier2Id || state.storefrontCarrier2Id,
            };
            break;
        default:
            newState = state;
            break;
    }
    return newState;
}