import React from "react";
import PropTypes from "prop-types";

const ReferenceNumberInput = ({
  referenceNumberSubmit,
  referenceNumberError,
  needsValidation,
  setReferenceNumberSubmit,
  handleEnter,
}) => {
  // Determine the input class based on validation logic
  const inputClass = needsValidation
    ? referenceNumberError || referenceNumberSubmit.length < 1
      ? "is-invalid"
      : "is-valid"
    : "";

  return (
    <input
      className={`form-control ${inputClass}`}
      data-cy="claim-reference-number"
      type="text"
      onChange={(e) => setReferenceNumberSubmit(e)}
      onKeyUp={handleEnter}
      value={referenceNumberSubmit}
      maxLength={15}
      required
    />
  );
};

ReferenceNumberInput.propTypes = {
  referenceNumberSubmit: PropTypes.string,
  referenceNumberError: PropTypes.bool,
  needsValidation: PropTypes.bool,
  setReferenceNumberSubmit: PropTypes.func,
  handleEnter: PropTypes.func,
};

export default ReferenceNumberInput;
