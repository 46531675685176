import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import ClaimLine from "./ClaimLine";
import { CSSTransition } from "react-transition-group";

const ClaimsSidebar = ({
  claimLines,
  uploadClaimAttachments,
  removeClaimItem,
  loadClaimLineItemForEdit,
  isEditMode,
  localized,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!isEditMode && claimLines.length > 0) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [claimLines, isEditMode]);

  return (
    <CSSTransition
      in={isVisible}
      timeout={300}
      classNames="sidebar-transition"
      unmountOnExit
    >
      <Col md={3} className="border-start border-black ps-5 gap-1">
        <Typography variant="h3" className="mb-5 fw-bold fs-1">
          {localized.ItemsAddedToClaim}
        </Typography>

        <div className="claims-sidebar-content">
          {claimLines.map((claimLine) => (
            <ClaimLine
              key={claimLine.serialNumber}
              claimLine={claimLine}
              uploadFiles={uploadClaimAttachments}
              removeClaimItem={removeClaimItem}
              loadClaimLineItemForEdit={loadClaimLineItemForEdit}
            />
          ))}
        </div>
      </Col>
    </CSSTransition>
  );
};

export default ClaimsSidebar;
