import * as types from './actionTypes';
import CartService from '../services/CartService';
import * as CartStatusNotifications from "../resources/CartStatusNotification";
import PricingHelper from "../helpers/PricingHelper";

export function AddItemToCart(item, localized, callback) {
    if (PricingHelper.isItemConfigured(item.product)) {
        CartService.addToCartCallback(CartStatusNotifications.ConfiguredItemsNotAvailable, localized);
        return ItemCouldNotBeAddedToCart();
    }
    if (PricingHelper.isItemObsolete(item.product)) {
        CartService.addToCartCallback(CartStatusNotifications.ObsoleteItemsNotAvailable, localized);
        return ItemCouldNotBeAddedToCart();
    }
    return function (dispatch) {
        dispatch(ItemAddedToCart(item));
        CartService.add(item)
            .then(res => { return res.json(); })
            .then(ret => {
                item.id = ret.id;
                //dispatch(ItemAddedToCart(item)); // maybe call update here, and add item added right away.
                dispatch(UpdateCartItem(item));
            })
            .then(() => {
                CartService.addToCartCallback(CartStatusNotifications.OrderUpdated, localized);
                if (callback)
                    callback();
            })
            .catch(() => CartService.addToCartCallback(CartStatusNotifications.UnableToAddToCart, localized));
    };
}

export function ItemAddedToCart(item) {
    return {
        type: types.ADD_ITEM, item: item
    };
}

export function UpdateCartItem(item) {
    return {
        type: types.UPDATE_ITEM, item: item
    };
}

export function UpdateAllCartItems(items) {
    return {
        type: types.UPDATE_ALL_ITEMS, items: items
    };
}
export function AddImportCartItems(items) {
    return {
        type: types.IMPORT_CART_ITEMS, items: items
    };
}

export function RemoveCartItem(id, callback) {
    return function (dispatch) {
        CartService.remove(id)
            .then(() => {
                dispatch(ItemRemovedFromCart(id));
            })
            .then(callback);
    };
}
export function ItemRemovedFromCart(id) {
    return {
        type: types.REMOVE_ITEM, id: id
    };
}

export function OrderLoaded(item) {
    return {
        type: types.ORDER_LOADED, item: item
    };
}

export function ClearCart(userId, paymentTerms, paymentInstrument, freightHandlingCode, callback) {
    return function (dispatch) {
        CartService.clear(userId)
            .then(() => {
                dispatch(CartCleared(paymentTerms, paymentInstrument, freightHandlingCode));
            })
            .then(callback);
    }
}

export function CartCleared(paymentTerms, paymentInstrument, freightHandlingCode, selectedBranch) {
    const preferences = { paymentTerms: paymentTerms, paymentInstrument: paymentInstrument, freightHandlingCode: freightHandlingCode, selectedBranch: selectedBranch };
    return {
        type: types.CLEAR_CART, item: preferences
    };
}

export function UpdateOrderHeader(freightHandlingCode, requestDate, paymentTerm, selectedBranch) {
    return {
        type: types.UPDATE_ORDER_HEADER, item: { freightHandlingCode: freightHandlingCode, requestDate: requestDate, paymentTerms: paymentTerm, selectedBranch: selectedBranch }
    };
}

export function UpdateOrderReview(orderReviewFields) {
   return {
        type: types.UPDATE_ORDER_REVIEW, item: orderReviewFields
    };
}

export function FetchPricing(id) {
    return {
        type: types.FETCH_PRICE, item: { id: id, type: "add" }
    };
}

export function ClearAllFetching() {
    return {
        type: types.CLEAR_FETCHING
    };
}

export function ItemCouldNotBeAddedToCart() {
    return {
        type: types.ITEM_CANNOT_BE_ADDED_TO_CART
    };
}

export function UpdateDropshipAddress(dropshipAddress) {
    return {
        type: types.DROPSHIP_UPDATED, item: dropshipAddress
    };
}