import React, { useEffect, useState, useRef } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import PropTypes from 'prop-types';
import CustomerSelector from '../components/CustomerSelector';
import CartIcon from './icons/CartIcon';
import UserIcon from './icons/User';
import SearchIcon from './icons/Search';
import Search from './Search';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { NavHashLink as Link } from 'react-router-hash-link';
import Auth from '../services/Auth';
import CheckBox from '../components/form-controls/CheckBox';
import Api from '../services/Api';
import UsersService from '../services/UserService';
import CustomerService from '../services/Customer';
import Notifications from '../services/Notifications';
import Authorize from './Authorize';
import * as userActionsFunctions from '../actions/userActions';
import * as Roles from '../resources/UserRole';
import CartService from '../services/CartService';
import * as cartActionsFunctions from '../actions/cartActions';
import { useHistory } from 'react-router-dom';
import { useCustomers } from '../hooks/CustomerContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { useStorefrontSettings, withStorefrontConfig } from '../hooks/StorefrontSettingsContext';
import LanguageSelect from './LanguageSelect';

export const UserBar = props => {
    const setLocale = (locale) => {
        props.StorefrontConfig.updateLocale(locale);
    }
    const storefrontConfig = useStorefrontSettings();
    const history = props.history || useHistory();
    const { customerList, currentCustomer, setCurrentCustomer, setCustomerList } = useCustomers();
    const dispatch = props.cartActions ? {} : useDispatch();
    const cartActions = props.cartActions ? props.cartActions : bindActionCreators(cartActionsFunctions, dispatch);
    const userActions = props.userActions ? props.userActions : bindActionCreators(userActionsFunctions, dispatch);
    const localized = storefrontConfig.labels;
    const [searchIsActive, setSearchIsActive] = useState(false);
    const [userMenuIsActive, setUserMenuIsActive] = useState(false);
    const curNode = useRef([]);
    const nodeToggle = useRef([]);
    const store = props.store ? props.store : useSelector((state) => state);
    const currentUser = store.user.currentUser;
    const cartCount = store.cart.items.length;
    const storeCustomerList = store.user.customers;
    const [defaultFHC, setDefaultFHC] = useState('');

    useEffect(() => {
        document.addEventListener("mousedown", handleClick, false);
        loadCustomerPreferences(currentCustomer, null);
        setDefaultFHC(storefrontConfig.settingsList.DefaultFreightHandlingCode);
        return function cleanup() {
            document.removeEventListener("mousedown", handleClick, false);
        }
    }, [storefrontConfig.storefrontSettingsLoaded]);

    useEffect(() => {
        setCustomerList(storeCustomerList);
        if (currentCustomer?.name === undefined && storeCustomerList.length > 0) {
            const updateCurrentCustomer = storeCustomerList[0];
            updateSelectedCustomer(updateCurrentCustomer);
            setCurrentCustomer(updateCurrentCustomer);
        }
        else if(currentCustomer && storeCustomerList.length < 1) {
            setCurrentCustomer({});
        }
    },[storeCustomerList]);

    const handleClick = (e) => {
        if (curNode.current.contains(e.target) || nodeToggle.current.contains(e.target))
            return;
        setUserMenuIsActive(false);
    }

    const updateSelectedCustomer = (customer) => {
        if (currentCustomer && currentCustomer?.id == customer.id) {
            return;
        }

        if (customer) {
            // Clear Cart for all storefronts whenever customer is changed not only brazil.
            if (cartCount > 0) {
                Notifications.confirmation(localized.ConfirmClearCart, () => {
                    customer = setCustomer(customer, (cust) => {
                        cartActions.ClearCart(currentUser.id, cust.paymentTermId, cust.paymentInstrument, (storefrontConfig.settingsList.DefaultFreightHandlingCode || defaultFHC));
                    });
                });
            }
            else {
                loadCustomerPreferences(customer, null);
                if (window.location.pathname.startsWith('/cart/review')) {
                    showCart();
                }
            }
        }
    }

    const setCustomer = (customer, customerUpdated) => {

        // load customer pref if company code exists - will call set current customer
        loadCustomerPreferences(customer, customerUpdated);
        CartService.resetItemsAvailability();
        // TODO: Add redux call to clear fetching
        cartActions.ClearAllFetching();
        // return updated customer with Prefs
        return customer;
    }

    const loadCustomerPreferences = (customer, customerUpdated) => {
        if (customer && customer?.companyCode && customer?.companyCode.length > 0) {
            CustomerService.lookupCustomerPreferences(customer).then((prefs) => {
                let cust = setFreightHandlingCodeByCompanyCode(prefs, customer);
                setCurrentCustomer(cust);
                // update references
                userActions.receivedCustomerPreferences({
                    paymentInstrument: cust.paymentInstrument,
                    paymentTermId: cust.paymentTermId,
                    freightHandlingCode: cust.freightHandlingCode,
                });
                // update cart store
                const fHC = prefs.freightHandlingCode === "" ? (storefrontConfig.settingsList.DefaultFreightHandlingCode || defaultFHC) : prefs.freightHandlingCode;
                cartActions.UpdateOrderHeader(fHC, new Date(), { paymentTermId: prefs.paymentTermsAR, paymentInstrument: prefs.paymentInstrument });
                CustomerService.storeSelectedCustomer(cust);
                if (customerUpdated) {
                    customerUpdated(cust);
                }
            });
        }
    }

    const setFreightHandlingCodeByCompanyCode = (prefs, curCustomer) => {
        currentUser.freightHandlingCode = (storefrontConfig.settingsList.DefaultFreightHandlingCode || defaultFHC);
        return {
            ...curCustomer,
            freightHandlingCode: (prefs.freightHandlingCode === '') ? (storefrontConfig.settingsList.DefaultFreightHandlingCode || defaultFHC) : prefs.freightHandlingCode,
            paymentInstrument: prefs.paymentInstrument,
            paymentTermId: prefs.paymentTermsAR,
            customerPORequired: prefs.customerPORequired,
        };
    }

    const userHasRole = (roles) => {
        const rolesList = currentUser.roles;
        return roles.some(function (r) {
            return rolesList.some(function (element) {
                return (r.toLowerCase() === element.toLowerCase());
            });
        });
    }

    const handleBetaToggle = () => {
        let method;
        currentUser.userFlags.includes("Beta") ? method = 'DELETE' : method = 'POST';
        Api.fetch('/api/user/flags?flagName=Beta', { method: method }).then(setFlags);
    }

    const handlePriceBypassToggle = () => {
        let method;
        (currentUser.userFlags.includes("PriceBypass")) ? method = 'DELETE' : method = 'POST'
        Api.fetch('/api/user/flags?flagName=PriceBypass', { method: method }).then(setFlags);
    }

    const setFlags = (res) => {
        if (res) {
            userActions.SetUserFlags(res);
            window.location.reload();
        }
    }

    const showSearch = () => {
        setSearchIsActive(true);
    }

    const hideSearch = () => {
        setSearchIsActive(false);
    }

    const showCart = () => {
        if (currentUser.storefront === '6400') {
            if (currentCustomer && !CustomerService.checkCreditHold(currentCustomer))
                history.push("/cart");
        }
        else
            history.push("/cart");
    }

    const showHelp = () => {
        history.push("/help");
    }

    const cartIsDisabled = () => {
        var billTo = currentCustomer && currentCustomer.billingType === "1";
        var userRoleNotAllowed = UsersService.current.role === "Standard_9No_Ordering0";
        return billTo || userRoleNotAllowed;
    }

    const toggleUserMenu = () => {
        setUserMenuIsActive(!userMenuIsActive);
    }

    const logout = () => {
        Auth.signOut();
    }

    return (
        <div className="fele-user-bar">
            <Link to="/">
                <HomeOutlinedIcon fontSize="large" style={{color: "black", height: "40px", width: "40px", marginLeft: "15px"}} />
            </Link>
            <div className={`search-wrapper ${searchIsActive && "active"}`}>
                <Search selectedCustomer={currentCustomer} />  {/*availabilityByLocation={availabilityByLocation} hideListPrice={hideListPrice} hideYourCost={hideYourCost} />*/}
                <button className="link" onClick={hideSearch}>{localized.Close}</button>
            </div>
            <div className="bar-left">
                <Link to="/">
                    <div className="logo"></div>
                </Link>
            </div>
            <div className="bar-right">
                <div className="search">
                    <div className="button-wrapper">
                        <button className="search-trigger" onClick={showSearch}>
                            <SearchIcon />
                            <div className="text display-tablet">{localized.SearchParts}</div>
                        </button>
                    </div>
                </div>
                <div className="shipto">
                    <CustomerSelector currentUser={currentUser} customers={customerList} selectedCustomer={currentCustomer} updateSelectedCustomer={updateSelectedCustomer} />
                </div>
                <div className="help">
                    <div className="button-wrapper">
                        <button onClick={showHelp} >
                            <FontAwesomeIcon icon={faQuestionCircle} size="2x" /><br />
                            <div className="text display-tablet">{localized.Help}</div>
                        </button>
                    </div>
                </div>
                <div className="cart">
                    <div className="button-wrapper">
                        <button className="cart-trigger" onClick={showCart} disabled={cartIsDisabled()}>
                            <CartIcon />
                        </button>
                    </div>
                </div>
                <div className={`user ${userHasRole(['BetaUser']) ? 'is-beta-user' : ''}`}>
                    <div className="button-wrapper">
                        <button className="user-menu-toggle" onClick={toggleUserMenu} ref={(node) => { nodeToggle.current = node; }}>
                            <UserIcon />
                            <div className="text display-tablet">{localized.Profile}</div>
                        </button>
                    </div>
                    <div id="userMenu" className={`menu ${userMenuIsActive ? "active" : ""}`} ref={(node) => { curNode.current = node; }}>
                        <Authorize hasRoles={[Roles.BETA_USER]} displayMessage={false}>
                            <div className="item">
                                <div className="button-wrapper">
                                    <CheckBox className="beta-toggle" onClick={handleBetaToggle} label={localized.EnableBetaFeatures} checked={currentUser.userFlags.includes("Beta")} />
                                </div>
                            </div>
                        </Authorize>
                        <Authorize hasRoles={[Roles.DEVELOPER]} displayMessage={false}>
                            <div className="item">
                                <div className="button-wrapper">
                                    <CheckBox className="price-bypass-toggle" onClick={handlePriceBypassToggle} label={localized.EnablePriceBypassFeature} checked={currentUser.userFlags.includes("PriceBypass")} />
                                </div>
                            </div>
                        </Authorize>
                        <LanguageSelect user={ store.user } localized={localized} changeLanguage={setLocale} language={props.StorefrontConfig.locale} />
                        <div className="button-wrapper">
                            <button className="item log-out" onClick={logout}>{localized.LogOut}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

UserBar.propTypes = {
    currentUser: PropTypes.object,
    cartCount: PropTypes.number,
    availabilityByLocation: PropTypes.bool,
    hideYourCost: PropTypes.bool
}

function mapStateToProps(state) {
    return {
        currentUser: state.user.currentUser,
        cartCount: state.cart.items.length,
        //hideListPrice: state.settings.storefront.hideListPrice,
        //shippingOptions: state.settings.storefront.shippingOptions,
        //availabilityByLocation: state.settings.storefront.availabilityByLocation,
        //hideYourCost: state.settings.storefront.hideYourCost,
    };
}


export default compose(
    connect(mapStateToProps,
        null)
)(withStorefrontConfig(UserBar));