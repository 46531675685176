import React, { useState, useEffect } from 'react';
import Api from '../services/Api';
import Strings from './Help.Strings';
import Notifications from "../services/Notifications";
import { useSelector } from 'react-redux';
import { useCustomers } from '../hooks/CustomerContext';

const requiredFields = ['CustomerName', 'CustomerNumber', 'Subject', 'FirstName', 'LastName', 'Email', 'Message'];

const Help = props => {
    const store = props.store ? props.store : useSelector((state) => state);
    const user = store.user.currentUser;
    const { currentCustomer: selectedCustomer } = useCustomers();
    const localized = Strings.localize();
    const defaultValues = {
        Subject: localized.SalesOrders,
        FirstName: user.firstName,
        LastName: user.lastName,
        Email: user.email,
        Phone: '',
        Extension: '',
        Fax: '',
        Message: ''
    };
    const [customer, setCustomer] = useState({
        CustomerName: selectedCustomer?.name,
        CustomerNumber: selectedCustomer?.id
    });

    const [formValues, setFormValues] = useState(defaultValues);

    const [sendingEmail, setSendingEmail] = useState(false);

    const setValue = e => {
        setFormValues({ ...formValues, [e.target.attributes.field.value]: e.target.value });
    }

    const handlechange = e => {
        setCustomer({ ...customer, [e.target.attributes.field.value]: e.target.value })
    }

    useEffect(() => {
        setCustomer({
            CustomerName: selectedCustomer?.name,
            CustomerNumber: selectedCustomer?.id
        })
    }, [selectedCustomer]);

    const checkRequiredValues = () => {
        let i = 0;
        while (i < requiredFields.length) {
            if (formValues[requiredFields[i]] === '' || customer[requiredFields[i]] === '') {
                Notifications.error(`${localized[requiredFields[i]]} is required.`);
                return false;
            }
            i++;
        }
        return true;
    }

    const handleError = e => {
        Notifications.error(e);
        setSendingEmail(false);
    }

    const handleSuccess = e => {
        Notifications.message(localized.MessageSent);
        setSendingEmail(false);
        history.back();
    }

    const handleFail = () => {
        Notifications.error(localized.FailedToSendMessage);
        setSendingEmail(false);
    }

    const sendEmail = async (supportData) => {
        return await Api.fetch("api/user/SendSupportEmail", {
            body: JSON.stringify(supportData),
            method: 'POST'
        });
    }

    const submit = async (e) => {
        if (checkRequiredValues()) {
            const supportData = {
                Customer: customer.CustomerName,
                Subject: formValues.Subject,
                CustomerNumber: customer.CustomerNumber,
                FirstName: formValues.FirstName,
                LastName: formValues.LastName,
                Phone: formValues.Phone,
                Extension: formValues.Extension,
                Fax: formValues.Fax,
                Message: formValues.Message,
                Email: formValues.Email,
                StorefrontNumber: user.storefront.number
            }

            setSendingEmail(true);

            try {

                const response = await sendEmail(supportData);
                const isSuccess = await response.json();

                if (!isSuccess) {
                    handleFail();

                    return;
                }

                handleSuccess(isSuccess);
            } catch (error) {
                handleError(error);
            }
        }
    }

    return (
        <div className="help-container">
            <div className="help-header">{localized.EmailUs}</div>
            <div className="help-form-container">
                <div className="label" >* {localized.SelectASubject}</div>
                <div className="help-row" >
                    <select className="help-input" field={'Subject'} onChange={setValue} value={formValues.Subject}>
                        <option value={localized.SalesOrders}>{localized.SalesOrders}</option>
                        <option value={localized.HelpWithYourAccount}>{localized.HelpWithYourAccount}</option>
                        <option value={localized.ProductSupport}>{localized.ProductSupport}</option>
                        <option value={localized.WebsiteSupport}>{localized.WebsiteSupport}</option>
                        <option value={localized.CustomerService}>{localized.CustomerService}</option>
                        <option value={localized.GeneralInquiries}>{localized.GeneralInquiries}</option>
                    </select>
                </div>
                <div className="label" >* {localized.Customer}</div>
                <div className="help-row" >
                    <input className="help-input" type="text" field={'CustomerName'} onChange={handlechange} value={customer.CustomerName} />
                </div>
                <div className="label" >* {localized.CustomerNumber}</div>
                <div className="help-row" >
                    <input className="help-input" type="text" field={'CustomerNumber'} onChange={handlechange} value={customer.CustomerNumber} />
                </div>
                <div className="label" >* {localized.FirstName}</div>
                <div className="help-row" >
                    <input className="help-input" type="text" field={'FirstName'} onChange={setValue} value={formValues.FirstName} />
                </div>
                <div className="label" >* {localized.LastName}</div>
                <div className="help-row" >
                    <input className="help-input" type="text" field={'LastName'} onChange={setValue} value={formValues.LastName} />
                </div>
                <div className="label" >* {localized.Email}</div>
                <div className="help-row" >
                    <input className="help-input" type="text" field={'Email'} onChange={setValue} value={formValues.Email} />
                </div>
                <div className="label" > {localized.Phone}</div>
                <div className="help-row"  >
                    <input className="help-input" type="text" field={'Phone'} onChange={setValue} value={formValues.Phone} />
                </div>
                <div className="label" >{localized.Extension}</div>
                <div className="help-row"  >
                    <input className="help-input" type="text" field={'Extension'} onChange={setValue} value={formValues.Extension} />
                </div>
                <div className="label" >{localized.Fax}</div>
                <div className="help-row">
                    <input className="help-input" type="text" field={'Fax'} onChange={setValue} value={formValues.Fax} />
                </div>
                <div className="label" >* {localized.Message}</div>
                <div className="help-row" >
                    <textarea className="help-input" style={{ height: '6rem' }} type="textarea" field={'Message'} onChange={setValue}
                        value={formValues.Message} maxLength={500} />
                </div>

                <div className="button-wrapper help-button-row" style={buttonRowStyle} >
                    {sendingEmail ?
                        <button className="button reprice-cart-button" style={{ width: '10rem' }} disabled={true} >{localized.Sending}</button>
                        :
                        <button className="button reprice-cart-button" style={{ width: '10rem' }} onClick={submit}>{localized.Send}</button>
                    }
                </div>
            </div>
        </div>
    )
}
export default Help;

const buttonRowStyle = { marginTop: '1rem', marginBottom: '1rem', paddingLeft: '2rem', paddingRight: '2rem' };
