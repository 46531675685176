import React from "react";
import PropTypes from "prop-types";

const AdditionalCommentsInput = ({
  setDetailsCommentSubmit,
  detailComment,
  editable,
}) => {
  const handleChange = (e) => {
    let comments = e.target.value;
    setDetailsCommentSubmit(comments);
  };

  return (
    <div className="fele-cart-comments">
      <div className="fele-content">
        <div className="input-wrapper">
          <textarea
            value={detailComment}
            onChange={handleChange}
            onBlur={handleChange}
            readOnly={editable}
            autoresizeenabled="false"
            rows="3"
            style={{ resize: "none" }}
          />
        </div>
      </div>
    </div>
  );
};

AdditionalCommentsInput.propTypes = {
  setDetailsCommentSubmit: PropTypes.func,
  detailComment: PropTypes.string,
  editable: PropTypes.bool,
};

export default AdditionalCommentsInput;
