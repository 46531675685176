import * as React from 'react';
import { useState, useEffect } from 'react';
import storefrontService from '../services/Storefront';
import { useStorefrontSettings } from './StorefrontSettingsContext';
import Localization from '../services/Localization';
import * as Components from '../resources/Components';

const SalesHistoryConfigContext = React.createContext();

function SalesHistoryConfigProvider({ children, ...props }) {

    // have all the config settings here.
    const [salesHistoryConfigLoaded, setSalesHistoryConfigLoaded] = useState(false);
    const [settings, setSettings] = useState(props.settings || []);
    const [childComponents, setChildComponents] = useState(props.childComponents || {});
    const [labels, setLabels] = React.useState({});

    // get current locale
    const { locale } = useStorefrontSettings();

    // might have to just make this a parameter that gets passed in
    const storefrontNumber = ('00000' + props.storefrontNumber).slice(-5);

    // useEffect?
    useEffect(() => {
        getSalesHistorySettings();
    }, []);

    useEffect(() => {
        Localization.getComponentLabels(Components.SALES_HISTORY, locale, storefrontNumber)
            .then(res => setLabels(res))
    }, [locale]);

    // fetchSettings
    const getSalesHistorySettings = async () => {
        try {
            await new Promise(resolve => setTimeout(resolve, 5000)); // 5 second delay

            const res = await storefrontService.getSalesHistorySettings(storefrontNumber);
            setSettings(res.settings || []);
            setChildComponents(res.childComponents || []);
            setSalesHistoryConfigLoaded(true);
        } catch (error) {
            console.error('Error fetching sales history settings:', error);
        }
    };

    return (
        <SalesHistoryConfigContext.Provider
            value={{
                settings,
                childComponents,
                salesHistoryConfigLoaded,
                labels,
            }}
        >
            {children}
        </SalesHistoryConfigContext.Provider >
    );
}

function useSalesHistoryConfig() {
    const context = React.useContext(SalesHistoryConfigContext);
    if (context === undefined) {
        throw new Error('useSalesHistoryConfig must be used within a SalesHistoryConfigContext');
    }

    return context;
}

function withSalesHistoryConfig(Component) {
    return function WrappedComponent(props) {
        const salesHistoryConfig = useSalesHistoryConfig();
        return <Component {...props}
            salesHistoryConfig={salesHistoryConfig}
        />;
    }
}

export { SalesHistoryConfigProvider, useSalesHistoryConfig, withSalesHistoryConfig }