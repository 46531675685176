import Api from './Api';

class Storefront {
    storefronts = {};

    fetchStorefronts = () => {
        return Api.fetch(`/api/Administrative/storefronts`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    getStorefrontSettings = (storefrontNumber) => {
        return Api.fetch(`/api/Settings/Storefront/${storefrontNumber}`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    getStorefrontOrderQuoteStatuses = (storefrontNumber) => {
        return Api.fetch(`/api/Settings/OrderQuoteStatuses/${storefrontNumber}`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    getBranchesByStorefrontNumber = (storefrontNumber) => {
        return Api.fetch(`/api/Settings/BranchesByNumber/${storefrontNumber}`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    getBranchDetails = (storefrontNumber) => {
        return Api.fetch(`/api/Settings/BranchDetails/${storefrontNumber}`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    getDashboardSettings = (storefrontNumber) => {
        return Api.fetch(`/api/Settings/DashboardSettings/${storefrontNumber}`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    getCartSettings = (storefrontNumber) => {
        return Api.fetch(`/api/Settings/CartSettings/${storefrontNumber}`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    getSalesHistorySettings = (storefrontNumber) => {
        return Api.fetch(`/api/Settings/SalesHistorySettings/${storefrontNumber}`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    getInvoiceSettings = (storefrontNumber) => {
        return Api.fetch(`/api/Settings/InvoiceSettings/${storefrontNumber}`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    getProductSettings = (storefrontNumber) => {
        return Api.fetch(`/api/Settings/ProductSettings/${storefrontNumber}`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    getAccountSummarySettings = (storefrontNumber) => {
        return Api.fetch(`/api/Settings/AccountSummarySettings/${storefrontNumber}`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    getWarrantySettings = (storefrontNumber) => {
        return Api.fetch(`/api/Settings/WarrantySettings/${storefrontNumber}`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    getPersonas = (storefrontNumber) => {
        return Api.fetch(`/api/Settings/Personas/${storefrontNumber}`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    getBranches = (storefrontId) => {
        return Api.fetch(`/api/Settings/Branches/${storefrontId}`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    getUsersSettings = (storefrontNumber) => {
        return Api.fetch(`/api/Settings/UsersSettings/${storefrontNumber}`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    getNotificationBannerSettings = async (page) => {
        try {
            const res = await Api.fetch(`/api/Settings/NotificationBannerSettings/${page}`);

            return res;
        } catch (error) {
            console.log("Failed to load notification banner.");
            console.log(error);
        }
    }

    handleError = (e) => {
        console.log("Failed to load storefronts.");
        console.log(e);
    }
}

export default new Storefront();