// Documentation is available at: https://dev.azure.com/fele-cc/FE-Direct/_wiki/wikis/FE-Direct.wiki/592/FontAwesomeSvgIcon
import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const FontAwesomeSvgIcon = React.forwardRef(function FontAwesomeSvgIcon(
  props,
  ref
) {
  const { icon, fontSize } = props;

  if (!icon || !Array.isArray(icon.icon)) {
    console.error(
      "Invalid FontAwesome icon passed. Please ensure that the icon object is valid."
    );
    return null;
  }

  const {
    icon: [width, height, , , svgPathData],
  } = icon;

  return (
    <SvgIcon ref={ref} viewBox={`0 0 ${width} ${height}`} fontSize={fontSize}>
      {typeof svgPathData === "string" ? (
        <path d={svgPathData} />
      ) : (
        svgPathData.map((d, i) => (
          <path key={i} d={d} style={{ opacity: i === 0 ? 0.4 : 1 }} />
        ))
      )}
    </SvgIcon>
  );
});

export default FontAwesomeSvgIcon;
