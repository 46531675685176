import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PropTypes from "prop-types";
import PDFIcon from "../../icons/PDFIcon";

const FilePreview = ({ file, handleRemoveFile }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [filePreviewUrl, setFilePreviewUrl] = useState("");

  const isImageFile = (fileName) => {
    const imageExtensions = ["png", "jpeg", "jpg"];
    const fileExtension = fileName.split(".").pop().toLowerCase();

    return imageExtensions.includes(fileExtension);
  };

  const isPdfFile = (fileName) => fileName.split(".").pop().toLowerCase() === "pdf";

  useEffect(() => {
    if (isImageFile(file.name)) {
      const objectUrl = URL.createObjectURL(file);
      setFilePreviewUrl(objectUrl);

      return () => {
        URL.revokeObjectURL(objectUrl);
      };
    }
  }, [file]);

  const positionDeleteIcon = () => {
    return { top: "20%", left: "90%" };
  };

  return (
    <div
      role="button"
      style={{
        position: "relative",
        width: "min-content",
        padding: "0",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onFocus={() => setIsHovered(true)}
      onBlur={() => setIsHovered(false)}
      onClick={() => handleRemoveFile(file.name)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          handleRemoveFile(file.name);
        }
      }}
      tabIndex={0}
    >
      {isPdfFile(file.name) && !isImageFile(file.name) ? (
        <PDFIcon />
      ) : (
        <img
          src={filePreviewUrl}
          alt={file.name}
          style={{
            width: "65px",
            aspectRatio: "16/9",
            objectFit: "cover",
            borderRadius: "3px",
          }}
        />
      )}

      {isHovered ? (
        <IconButton
          style={{
            position: "absolute",
            ...positionDeleteIcon(),
            transform: "translate(-50%, -50%)",
            backgroundColor: "#D9D9D9",
          }}
          size="small"
          tabIndex={-1}
        >
          <DeleteForeverIcon style={{ color: "#000000", fontSize: "large" }} />
        </IconButton>
      ) : null}
    </div>
  );
};

FilePreview.propTypes = {
  file: PropTypes.instanceOf(File),
  handleRemoveFile: PropTypes.func,
};

export default FilePreview;
