import React from "react";
import { Modal, Button } from "react-bootstrap";
import Strings from "../translations/ModalPopup.Strings";

const ModalPopup = ({ show, onHide, message, callback }) => {
  const localized = Strings.localize();

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      centered
      animation={false}
    >
      <div className="button-wrapper">
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
      </div>

      <Modal.Body>{message}</Modal.Body>

      <Modal.Footer>
        <div className="button-wrapper">
          <Button
            variant="secondary"
            className="button button-secondary"
            onClick={onHide}
          >
            {localized.NoButton_Label}
          </Button>
        </div>

        <div className="button-wrapper">
          <Button variant="primary" className="button" onClick={callback}>
            {localized.YesButton_Label}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPopup;
