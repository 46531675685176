import React from 'react';
import uuid from 'react-uuid'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ItemsSummary from '../cart/ItemsSummary';
import ItemsReview from '../cart/ItemsReview';
import OrderPlaced from '../cart/OrderPlaced';
import Api from '../../services/Api';
import Notifications from '../../services/Notifications';
import * as orderDocTypes from '../../helpers/OrderDocTypes';
import CartService from '../../services/CartService';
import PricingHelper from '../../helpers/PricingHelper';
import QuoteService from '../../services/QuoteService';
import { withRouter } from 'react-router-dom';
import DateHelper from "../../helpers/DateHelper";
import { withSelectedCustomer } from '../../hooks/withSelectedCustomer';
import { withStorefrontConfig } from '../../hooks/StorefrontSettingsContext';
import { withCartConfig } from '../../hooks/CartConfigContext';

export class EditQuote extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reviewingQuote: false,
            repriceMessageDisplayed: false,
            calculating: false,
            fetching: true,
            quote: null,
            subTotal: 0,
            showDropship: false,
            placingOrder: false,
            currency: null,
            orderPlaced: false
        };
    }

    componentDidMount() {
        this.mounted = true;
        Api.fetch(`/api/Quotes/GetFullQuote/${this.props.externalId}`).then(this.loadQuote);
    }

    loadQuote = (data) => {
        if (data) {
            data.requestDate = this.setQuoteDate(data.requestDate);
            //E1 uses DeliveryInstructions2 as sfcarrier2Id
            data.deliveryInstructions2 = this.props.CartConfig.childComponents.DeliveryInstructions2.shown === true ? data.storefrontCarrier2Id : null;
            for (let i = 0; i < data.items.length; i++) {
                if (!data.items[i].requestedDeliveryDate) {
                    data.items[i].requestedDeliveryDate = data.requestDate;
                }
                else {
                    let itemRequestDate = new Date(data.items[i].requestedDeliveryDate);
                    if (itemRequestDate < data.requestDate) {
                        data.items[i].requestedDeliveryDate = data.requestDate;
                    }
                    else {
                        data.items[i].requestedDeliveryDate = itemRequestDate;
                    }
                }
            }
            let currency = PricingHelper.getNetCurrency(data.items);
            let subTotal = PricingHelper.getNetSubtotal(data.items);

            //Call to load comments which are stored as buffer in ODS
            Api.fetch(`/api/orders/getCommentsBySale/${data.quoteNumber}/${data.documentCompany}/${data.docType}`).then(response => {
                data.comments = response;
            });
           

            this.setMountedState({ quote: data, fetching: false, subTotal: subTotal, currency: currency });
        }

    }

    useEffect = (callback, dependencies) => { }

    componentWillUnmount() {
        this.mounted = false;
    }

    setMountedState = (state, callback) => {
        if (this.mounted)
            this.setState(state, () => callback && callback());
    }

    updateQuote = (key, value, repriceMessageDisplayed = true, showOrderUpdatedMessage = true) => {
        let quote = this.state.quote;
        quote[key] = value;
        if (key === "requestDate") {
            quote.requestDate = value;
            for (let i = 0; i < quote.items.length; i++) {
                quote.items[i].requestedDeliveryDate = value
                
            }
        }
        this.setMountedState({ quote: quote, repriceMessageDisplayed: repriceMessageDisplayed });
        if (showOrderUpdatedMessage) {
            Notifications.message(this.props.CartConfig.labels.OrderUpdated);
        }

    }

    updateLineItemQuantity = (item, quantity) => {
        item.quantity = quantity;
        this.updateCartItem(item);
    }

    removeItem = (id) => {
        let item = this.state.quote.items.find(function (i) {
            return i.id === id;
        });
        if (!item.lineNumber) { //Completely remove items that were not part of the quote before editing
            let items = this.state.quote.items;
            items = items.filter(function (i) {
                return i.id !== id;
            });
            this.updateQuote("items", items);
        }
        else {
            item.isCancelled = true;
            this.updateCartItem(item);
        }
    }

    addItemToCart = (item) => {
        let items = this.state.quote.items;
        item.id = uuid();
        items.push(item);
        this.updateQuote("items", items);
    }

    updateCartItem = (item,isDateItem=false) => {
        let items = this.state.quote.items;
        for (let i = 0; i < items.length; i++) {
            if (items[i].id === item.id) {
                items[i] = item;
                break;
            }
        }
        
        this.updateQuote("items", items,!isDateItem,!isDateItem);
    }

    paymentTermsUpdated = (payTerms) => {
        this.updateQuote("paymentTermId", payTerms.paymentTermId);
        this.updateQuote("paymentInstrument", payTerms.paymentInstrument, true, false);
    }

    freightHandlingCodeUpdate = (e) => {
        this.updateQuote("freightHandlingCode", e.target.value !== "" ? e.target.value : this.props.StorefrontConfig.settingsList.DefaultFreightHandlingCode);
    }

    updateDate = (date) => {
        if (!date) {
            date = null;
        }
        else {
            this.updateQuote("requestDate", date);
        }
    }

    reviewIfItemsAvailable = () => {
        if (this.allItemsAreAvailable()) {
            this.toggleReviewingCart();
        }
    }

    toggleReviewingCart = () => {
        
        this.setMountedState({ reviewingQuote: !this.state.reviewingQuote });
    }

    allItemsAreAvailable() {
        if (this.state.fetching) {
            Notifications.message(this.props.CartConfig.labels.WaitForPriceAndAvailability)
            return false;
        }
        if (this.state.repriceMessageDisplayed) {
            Notifications.message(this.props.CartConfig.labels.UpateCartPriceMessage);
            return false;
        }

        for (let i = 0; i < this.state.quote.items.length; i++) {
            let item = this.state.quote.items[i];

            if (item.availability === "Unavailable" && item.isCancelled === false) {
                Notifications.error(this.props.CartConfig.labels.UnavailableItemsInCartMessage);
                return false;
            }
        }
        return true;
    }

    repriceCart = () => {
        
        let quote = { ...this.state.quote };
        quote.items = quote.items.filter(function (i) {
            return i.isCancelled !== true;
        });
        const useOrderType = this.props.StorefrontConfig.settingsList.UseOrderType == "" ? orderDocTypes.SALES_ORDER : this.props.StorefrontConfig.settingsList.UseOrderType;
        const order = CartService.getSubmitOrderBodyEComm({
            ...quote,
            docType: useOrderType,
            selectedCustomer: this.props.selectedCustomer,
            useCustomerInventoryPreference: this.props.CartConfig.settings.UseCustomerInventoryPreference,
            implementationId: this.props.StorefrontConfig.storefront.implementationId,
            storefrontNumber: this.props.currentUser.storefront.number
        });
        this.setMountedState({ fetching: true });
        CartService.fetchPriceCartEComm(order).then(this.fetchingPriceFinished);
        
    }

    fetchingPriceFinished = res => {
        this.setState({ calculating: true });
        if (res.items) {

            let pricedItems = res.items;
            let quote = this.state.quote;
            quote.items = PricingHelper.mapPricedItems(quote.items, pricedItems, quote.requestDate);
            let currency = PricingHelper.getNetCurrency(quote.items);
            let subTotal = PricingHelper.getNetSubtotal(quote.items);
            this.setMountedState({ quote: quote, currency: currency, subTotal: subTotal });
        }
        else {
            Notifications.error(this.props.CartConfig.labels.FetchingPriceFailed);
        }
        this.setMountedState({ fetching: false, repriceMessageDisplayed: false });
        this.setState({ calculating: false });
    }

    submitOrder = () => {
        if (this.carrierIdSelected()) {
            this.setMountedState({ placingOrder: true });
            QuoteService.submitEditQuote(this.getEditQuoteBody())

            this.props.history.push({ pathname: '/cart/confirmation', state: { IsQuote: true } });
        }
    }

    getEditQuoteBody = () => {
        const deliveryInstructions2 = (this.props.CartConfig.childComponents.DeliveryInstructions2.shown === true || this.state.quote.storefrontCarrier2Id === '')
                ? this.state.quote.deliveryInstructions2 || "0" : this.state.quote.storefrontCarrier2Id;
        return {
            documentNumber: this.state.quote.quoteNumber,
            documentCompany: this.props.selectedCustomer.companyCode,
            documentType: "SQ",
            customerId: this.props.selectedCustomer.id,
            carrierId: this.state.quote.storefrontCarrierId,
            comments: this.state.quote.comments,
            deliveryInstruction1: this.state.quote.deliveryInstructions,
            deliveryInstruction2: deliveryInstructions2,
            freightHandlingCode: this.state.quote.freightHandlingCode,
            paymentTermsCode: this.state.quote.paymentTermId,
            paymentInstrumentCode: this.state.quote.paymentInstrument,
            requestedDate: DateHelper.format(this.state.quote.requestDate, 'MM/dd/yyyy'),
            customerPO: this.state.quote.poNumber,
            implementationId: this.props.StorefrontConfig.storefront.implementationId,
            items: this.state.quote.items.map(this.toSubmit())
        }
    }

    toSubmit() {
        return i => {
            return {
                productSKU: i.product.sku,
                requestedDate: i.requestedDeliveryDate ? DateHelper.format(i.requestedDeliveryDate, 'MM/dd/yyyy') : i.requestedDeliveryDate,
                quantity: i.quantity,
                priceCode1: i.pc1DiscountCodeId || '',
                priceCode2: (i.pc2DiscountCodeId === "" || i.pc2DiscountCodeId === null) ? '00' : i.pc2DiscountCodeId, //Pass 00 to EComm for resetting discount
                priceCode3: (i.pc3DiscountCodeId === "" || i.pc3DiscountCodeId === null) ? '00' : i.pc3DiscountCodeId, 
                documentLineNumber: i.lineNumber,
                isDeleted: i.isCancelled
            };
        }
    }

    handleSubmitEditQuoteResponse = (response) => {
        this.setMountedState({ orderPlaced: true });
    }

    carrierIdSelected = () => {
        if (this.props.currentUser.storefrontNumber === "8700" && !this.state.quote.storefrontCarrierId) {
            Notifications.error(this.props.CartConfig.labels.CarrierRequiredMessage);
            return false;
        }
        else {
            return true;
        }
    }

    poNumberUpdated = (poNumber) => {
        this.updateQuote("poNumber", poNumber, false, false);
    }

    deliveryInstructionsUpdated = (deliveryInstructions) => {
        this.updateQuote("deliveryInstructions", deliveryInstructions, false, false);
    }

    deliveryInstructions2Updated = (deliveryInstructions) => {
        this.updateQuote("deliveryInstructions2", deliveryInstructions, false, false);
    }

    commentsUpdated = (comments) => {
        this.updateQuote("comments", comments, false, false);
    }

    carriersUpdated = (carriers) => {
        this.updateQuote("storefrontCarrierId", carriers.carrier1, false, false);
        this.updateQuote("storefrontCarrier2Id", carriers.carrier2, false);
    }

    getOrderNumber = () => {
        let externalId = this.props.externalId.toString();
        let orderNumber = '';
        if (externalId && externalId.includes('-')) {
            orderNumber = externalId.split('-')[0];
        }
        return parseInt(orderNumber);
    }

    setQuoteDate = (date) => {
        let newDate = new Date();
        if (!date) {
            date = newDate;
        }
        else {
            date = new Date(date);
            if (date < newDate) {
                date = newDate;
            }
        }
        return date;
    }

    render() {
        if (!this.state.quote) {
            return null;
        }
        const dateFormat = this.props.StorefrontConfig.settingsList.DateFormat;
        const { selectedCustomer, currentUser, components } = this.props;
        const { freightHandlingCode, paymentInstrument, paymentTermId, requestDate, poNumber, comments, deliveryInstructions, deliveryInstructions2, shippingMethod, dropshipAddress, storefrontCarrierId, storefrontCarrier2Id } = this.state.quote;
        const { repriceMessageDisplayed, fetching, placingOrder, subTotal, currency, showDropship, reviewingQuote, orderPlaced } = this.state;
        let items = this.state.quote.items.filter(function (i) {
            return i.isCancelled !== true;
        });
        return (

            <React.Fragment>
                {
                    !reviewingQuote && !orderPlaced &&
                    <ItemsSummary
                        editingQuote={true}
                        selectedCustomer={selectedCustomer}
                        currentUser={currentUser}
                        items={items}
                        freightHandlingCode={freightHandlingCode}
                        paymentInstrument={paymentInstrument}
                        paymentTermId={paymentTermId}
                        requestDate={requestDate}
                        dateFormat={dateFormat}
                        repriceMessageDisplayed={repriceMessageDisplayed}
                        fetching={fetching}
                        currency={currency}
                        subTotal={subTotal}
                        updateLineItemQuantity={this.updateLineItemQuantity}
                        updateCartItem={this.updateCartItem}
                        removeItem={this.removeItem}
                        addItemToCart={this.addItemToCart}
                        updateOrderHeader={() => { }}
                        paymentTermsUpdated={this.paymentTermsUpdated}
                        freightHandlingCodeUpdate={this.freightHandlingCodeUpdate}
                        updateDate={this.updateDate}
                        reviewQuote={() => { }}
                        reviewIfItemsAvailable={this.reviewIfItemsAvailable}
                        repriceCart={this.repriceCart}
                        calculating={this.state.calculating}
                        localized={this.props.CartConfig.labels}
                        headerRequestDate={this.state.quote.requestDate }
                        components={components}
                        loadCustomerDefaultPaymentTerms={() => { }}
                    />
                }
                {
                    reviewingQuote && !orderPlaced &&
                    
                    <ItemsReview
                        currentUser={currentUser}
                        orderIsQuote={true}
                        showDropship={showDropship}
                        requiredPO={this.props.CartConfig.childComponents.QuotePONumber.required === true || false}
                        placingOrder={placingOrder}
                        dateFormat={dateFormat}
                        comments={comments}
                        currency={currency}
                        deliveryInstructions={deliveryInstructions}
                        deliveryInstructions2={deliveryInstructions2}
                        items={items}
                        poNumber={poNumber}
                        requestDate={requestDate}
                        selectedCustomer={selectedCustomer}
                        shippingMethod={shippingMethod}
                        storefrontCarrierId={storefrontCarrierId}
                        storefrontCarrier2Id={storefrontCarrier2Id}
                        subTotal={subTotal}
                        dropshipAddress={dropshipAddress}
                        updateDropshipAddress={() => { }}
                        toggleReviewingCart={this.toggleReviewingCart}
                        toggleDropship={() => { }}
                        submitOrder={this.submitOrder}
                        shippingMethodUpdated={() => { }}
                        requestDateUpdated={() => { }}
                        poNumberUpdated={this.poNumberUpdated}
                        deliveryInstructionsUpdated={this.deliveryInstructionsUpdated}
                        deliveryInstructions2Updated={this.deliveryInstructions2Updated}
                        commentsUpdated={this.commentsUpdated}
                        carriersUpdated={this.carriersUpdated}
                        components={components}
                            />
                   
                }
                {
                    orderPlaced &&
                    <OrderPlaced IsQuote={true} />
                    }
            </React.Fragment>
        )
    }
}

EditQuote.propTypes = {
    currentUser: PropTypes.object,
    selectedCustomer: PropTypes.object,
    dateFormat: PropTypes.string,
    externalId: PropTypes.string,
    components: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        currentUser: state.user.currentUser
    };
}

export default withRouter(connect(
    mapStateToProps,
    null
)(withStorefrontConfig(withSelectedCustomer(withCartConfig(EditQuote)))));