import React from "react";
import PropTypes from "prop-types";

const LoyaltyIDInput = ({
  loyaltyIDSubmit,
  needsValidation,
  loyalityIDWarning,
  setLoyaltyIDSubmit,
  handleEnter,
}) => {
  const inputClass =
    needsValidation && loyaltyIDSubmit.length > 0
      ? loyalityIDWarning
        ? "is-invalid"
        : "is-valid"
      : "";

  return (
    <>
      <input
        data-cy="loyaltyid-submit"
        type="text"
        className={`form-control ${inputClass}`}
        onChange={(e) => setLoyaltyIDSubmit(e)}
        onKeyUp={handleEnter}
        value={loyaltyIDSubmit}
        maxLength={8}
      />

      {loyalityIDWarning ? (
        <div className="invalid-feedback">*numerics only</div>
      ) : null}
    </>
  );
};

LoyaltyIDInput.propTypes = {
  loyaltyIDSubmit: PropTypes.string,
  needsValidation: PropTypes.bool,
  loyalityIDWarning: PropTypes.bool,
  setLoyaltyIDSubmit: PropTypes.func,
  handleEnter: PropTypes.func,
};

export default LoyaltyIDInput;
