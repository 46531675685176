import React from "react";
import PropTypes from "prop-types";
import ErrorIcon from "../../icons/Error";
import WarningIcon from "../../icons/Warning";

const Alert = ({ type, message }) => {
    const icons = {
        warning: <WarningIcon />,
        error: <ErrorIcon />,
    };

    const classType = type === "warning" ? "triangle-warning" : "circle-error";

    return (
        <div
            className={`d-flex gap-4 align-items-center alert alert-${type} ${classType}`}
        >
            {icons[type]}
            <span className="span-font">{message}</span>
        </div>
    );
};

Alert.propTypes = {
    type: PropTypes.oneOf(["warning", "error"]),
    message: PropTypes.string,
};

export default Alert;
